const linkSubdirectory = () => {
  setTimeout(() => {
    const languageList = document.getElementById('lang-selector-list');

    const links = document.querySelectorAll('body a');
    const pathname = window.location.pathname;

    const languageOptions = {};

    if (languageList) {
      languageList.querySelectorAll('li').forEach(el => {
        languageOptions[el.dataset?.language] = el.innerHTML;
      });
    }
    const langCodes = Object.keys(languageOptions);

    const currLang =
      langCodes.find(lang => pathname.includes(`/${lang}/`)) ?? 'en';

    if (currLang !== 'en') {
      links.forEach(link => {
        const linkPath = link.href.split(window.location.host)[1] ?? link.href;

        const cleanPath = langCodes.reduce((acc, curr) => {
          return acc.replaceAll(`/${curr}/`, '/');
        }, linkPath);

        if (
          cleanPath[0] === '/' &&
          !cleanPath.includes('my-account') &&
          !cleanPath.includes('blog')
        ) {
          link.href = `${`/${currLang}`}${cleanPath}`;
        }
      });
    }
  }, 1000);
};

export default linkSubdirectory;
