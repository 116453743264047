import React, { useState, useRef } from 'react';
import getCookie from '~/js/modules/includes/get-cookie';
import VerticalQQ from './verticalQQ';
import HorizontalQQ from './horizontalQQ';
import CustomQQ from './customQQ';

const QQ = props => {
  const {
    className = '',
    onChange,
    rstCode = '',
    categoryCode = '',
    horizontal = false,
    horizontalSpecial = false,
    theme = '',
    withFlightsId = ['with-flights', 'with-flights-1'],
    setQQData = null,
    resortSelect,
    storeResortCode = false,
  } = props;

  const oldVacationData = useRef('');

  const savedRstCode = storeResortCode
    ? sessionStorage.getItem('rstCode')
    : null;

  const isResortAvailable =
    savedRstCode && resortSelect
      ? resortSelect?.resortOptions?.find(
          resortInfo =>
            resortInfo.code.toLowerCase() === savedRstCode.toLowerCase()
        )
      : false;

  const rstCodeValue =
    storeResortCode && isResortAvailable ? savedRstCode : rstCode;

  const [vacationData, setVacationData] = useState({
    referral: getCookie('COBRAND_COOKIE'),
    agentId: getCookie('COBRAND_AGENT'),
    rstCode: rstCodeValue,
    categoryCode: categoryCode,
    checkInDate: '',
    checkOutDate: '',
    gateway: '',
    seatType: 'economy',
    arrivalGateway: '',
  });

  const alignment =
    !horizontal && !horizontalSpecial ? 'vertical' : 'horizontal';

  const saveVacationData = el => {
    const newVacationData = { ...vacationData, ...el };

    oldVacationData.current = vacationData;
    setVacationData(newVacationData);
    onChange(newVacationData);

    if (setQQData) {
      setQQData(prevState => ({
        ...prevState,
        ...newVacationData,
      }));
    }
  };

  const activeABTest = sessionStorage.getItem('ABTestID');

  return (
    <div>
      {activeABTest === '1086047' && (horizontal || horizontalSpecial) && (
        <div
          className={`ssv-info-message ${
            vacationData.rstCode === 'SSV'
              ? 'show-item'
              : oldVacationData.current.rstCode === 'SSV'
              ? 'hide-item'
              : ''
          }`}
        >
          <div className="row">
            <div className="xs-12">
              <span>
                Best arrival + departure flights: USA: Mon, Wed, Sat. CANADA:
                Mon, Tue, Thu and Fri.
              </span>
            </div>
          </div>
        </div>
      )}
      <div className={`qq-wrapper ${alignment} ${className}`}>
        {!horizontal && !horizontalSpecial && !theme && (
          <VerticalQQ
            {...props}
            alignment={alignment}
            saveVacationData={saveVacationData}
            vacationData={vacationData}
            withFlightsId={withFlightsId}
          />
        )}
        {(horizontal || horizontalSpecial) && !theme && (
          <HorizontalQQ
            {...props}
            alignment={alignment}
            saveVacationData={saveVacationData}
            vacationData={vacationData}
            horizontalSpecial={horizontalSpecial}
            withFlightsId={withFlightsId}
          />
        )}
        {!horizontal && !horizontalSpecial && theme !== '' && (
          <CustomQQ
            {...props}
            alignment={alignment}
            saveVacationData={saveVacationData}
            vacationData={vacationData}
            withFlightsId={withFlightsId}
            setCustomData={setQQData}
            categoryCode={categoryCode}
          />
        )}
      </div>
    </div>
  );
};

export default QQ;
