import React, { useState, useRef, useEffect } from 'react';

import _ from '~/js/modules/utils';
import ResolveImg from '~/js/components/global/image/ResolveImg';
import hideByRegion from '~/js/modules/includes/hide-by-region';
import QQ from '~/js/components/global/qq';

import GcvFormik from '~/js/components/general/accounts/GcvFormik.js';
import ABTastyWrapper from '../abtasty/abtasty-wrapper';
import Modal from '../modal/Modal';
import getCookie from '../../../modules/includes/get-cookie';
import setCookie from '../../../modules/includes/set-cookie';

import useWindowDimensions from '~/js/modules/hooks/useWindowDimensions';

// Main HeaderV1 Component
const Header = ({ headerData, resorts, accountsInformation }) => {
  const ABTestPromotionBanner = sessionStorage.getItem('ABTestPromotionBanner');

  const showPromoBanner = ABTestPromotionBanner === '1';

  const choiceData =
    Object.keys(headerData?.choiceData).length > 0
      ? JSON.parse(headerData?.choiceData)
      : {};

  const hasVisitedChoiceReferral = getCookie('VISITED_CHOICE_REFERRAL');

  const {
    referral,
    headerSolidColorPage,
    searchActive,
    languageDisp,
    languageOptions,
    promoBanner,
    headerSections,
    currentPromotion,
    AccountsActive,
  } = headerData;

  const isChoiceReferral = referral?.status === 'CHOICE';

  const choiceCobrandData = isChoiceReferral ? choiceData.CHOICE : null;

  let bodyRef = null;

  let headerLinks = null;

  let headerButtons = null;

  let focusableTags = [];

  const headerWrapperRef = useRef(null);

  const headerRef = useRef(null);
  const mobileToggleRef = useRef(null);
  const menuRef = useRef(null);

  const searchBtnRef = useRef(null);
  const searchLinkRef = useRef(null);
  const searchPanelRef = useRef(null);
  const searchCloseButtonRef = useRef(null);
  const searchInputRef = useRef(null);
  const searchResetRef = useRef(null);
  const bookingButtonRef = useRef(null);

  const outsideClickRef = useRef(null);

  const accountsLoginRef = useRef(null);
  const accountsPanelRef = useRef(null);
  const userProfileRef = useRef(null);
  const userPanelRef = useRef(null);
  const languageSelRef = useRef(null);
  const languageListRef = useRef(null);
  const languageContainerRef = useRef(null);

  const isMobile = windowWidth => windowWidth < 768;

  const { width } = useWindowDimensions();
  const isTablet = width < 1199;

  let scrollPosition = window.scrollY;

  let lastScrollTop = 0;

  const redirectUrl = '/search/?searchTerm=';

  const [activeCat, setActiveCat] = useState(false);
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [isOutsideActive, setOutsideActive] = useState(false);

  const [isWrapperActive, setWrapperActive] = useState(false);
  const [isWrapperActiveHide, setWrapperActiveHide] = useState(false);
  const [isBookingOpen, setBookingOpen] = useState(false);
  const [isSearchOpen, setSearchOpen] = useState(false);
  const [isAccountsOpen, setAccountsOpen] = useState(false);
  const [isAccountsScrolling, setAccountsScrolling] = useState(false);

  const [isUserPanelOpen, setUserPanelOpen] = useState(false);
  const [isSearchResetActive, setSearchResetActive] = useState(false);
  const [isMainNavScrollable, setMainNavScrollable] = useState(false);

  const [currentLang, setCurrentLang] = useState('English');
  const [isLanguageSelectorOpen, setLanguageSelectorOpen] = useState(false);

  const [isLoggedIn, setLoggedIn] = useState(false);

  const [showChoiceModal, setShowChoiceModal] = useState(
    isChoiceReferral && !hasVisitedChoiceReferral
  );

  if (isChoiceReferral) {
    if (!hasVisitedChoiceReferral) {
      setCookie('VISITED_CHOICE_REFERRAL', 'true', 100000);
    }
  }

  const promoBannerExists = promoBanner.length > 0;

  const [isPromoBannerVisible, setPromoBannerVisible] = useState(
    Object.keys(referral).length === 0 && promoBannerExists
  );

  const closeCategories = () => {
    setActiveCat(false);
  };

  // Close Booking
  const closeBooking = () => {
    setBookingOpen(false);
  };

  // Close Search
  const closeSearch = () => {
    if (isSearchOpen && isMobile(window.innerWidth)) {
      setTimeout(() => {
        setSearchOpen(false);
      }, 500);
    } else {
      setSearchOpen(false);
    }
  };

  // Close Accounts
  const closeAccounts = () => {
    isMobile(window.innerWidth)
      ? setTimeout(() => {
          setAccountsOpen(false);
        }, 500)
      : setAccountsOpen(false);
  };

  // Global classes
  const menuHandler = isOpen => {
    setMenuOpen(isOpen);
    setOutsideActive(isOpen);
    bodyRef = document.querySelector('body');

    if (isOpen) {
      isMobile(window.innerWidth) && (bodyRef.style.overflow = 'hidden');
    } else {
      isMobile(window.innerWidth) && (bodyRef.style.overflow = 'visible');
      closeBooking();
      closeSearch();
      closeAccounts();
      closeCategories();
    }
  };

  const languageOpts = {};

  languageOptions.forEach(lang => {
    languageOpts[lang.url] = lang.title;
  });

  const languageChange = () => {
    const langCodes = Object.keys(languageOpts);
    const currLang =
      langCodes.find(lang => window.location.pathname.includes(`/${lang}/`)) ??
      'en';

    setCurrentLang(languageOpts[currLang]);

    languageListRef.current.querySelectorAll('li').forEach(item => {
      const cleanPath = langCodes.reduce((acc, curr) => {
        return acc === `/${curr}` ? '/' : acc.replaceAll(`/${curr}/`, '/');
      }, window.location.pathname);

      item.href = `${
        item.dataset.language === 'en' ? '' : `/${item.dataset.language}`
      }${cleanPath}`;
    });
  };

  // A11y
  const onSkipContentKeyUp = e => {
    e.preventDefault();

    const mainContent = document.querySelector('.main-content');
    const mainElLinks = mainContent.querySelectorAll('a');
    const mainElButtons = mainContent.querySelectorAll('button');

    if (e.keyCode === 13) {
      mainElLinks[0]?.focus() || mainElButtons[0]?.focus();
    }
  };

  // Scroll Header
  const scrollHeader = () => {
    const secondMenu = document.querySelector('.sticky-nav-wrapper');
    const scrollLimit =
      secondMenu !== null
        ? window.scrollY + secondMenu.getBoundingClientRect().y
        : 99999;

    scrollPosition = window.scrollY;
    if (scrollPosition > 10 && scrollPosition < scrollLimit) {
      setWrapperActive(true);
      setAccountsScrolling(true);
      setWrapperActiveHide(false);
    } else if (scrollPosition >= scrollLimit) {
      setWrapperActive(true);
      setWrapperActiveHide(true);
      menuHandler(false);
    } else {
      setWrapperActive(false);
      setAccountsScrolling(false);
      setWrapperActiveHide(false);
    }

    if (scrollPosition < lastScrollTop) {
      // upscroll code
      setWrapperActiveHide(false);
    }
    lastScrollTop = scrollPosition <= 0 ? 0 : scrollPosition;
  };

  // Runs when page loads, if the user views the page below the sticky nav bar position on reload, hides the transparent background header
  const onReloadHideHeader = () => {
    const secondMenu = document.querySelector('.sticky-nav-wrapper');
    const scrollLimit =
      secondMenu !== null
        ? window.scrollY + secondMenu.getBoundingClientRect().y
        : 99999;

    scrollPosition = window.scrollY;

    if (scrollPosition >= scrollLimit) {
      setWrapperActive(true);
      setWrapperActiveHide(true);
    }
  };

  const getMobileWindowHeight = () => {
    if (isMobile) {
      const vh = window.innerHeight * 0.01;

      document.documentElement.style.setProperty('--vh', `${vh}px`);
    }
  };

  // Resize
  const resizeScreen = _.debounce(() => {
    if (!isMobile(window.innerWidth)) {
      menuHandler(false);
    }
    getMobileWindowHeight();
  });

  const onDocumentKeyUp = () => {
    const { activeElement } = document;
    const isMenuElement = focusableTags.includes(activeElement);
    const searchFocused = activeElement.classList.contains('search__bar');

    if (searchFocused || isAccountsOpen) {
      return;
    }

    if (!isMenuElement && isMenuOpen && !isSearchOpen && !isBookingOpen) {
      menuHandler(false);
    }
  };

  useEffect(() => {
    languageChange();

    headerLinks = headerRef.current.querySelectorAll('a');
    headerButtons = headerRef.current.querySelectorAll('button');
    focusableTags = [...headerLinks, ...headerButtons];

    const skipContent = document.getElementById('js-skip__content');

    skipContent.addEventListener('keyup', onSkipContentKeyUp);
    window.addEventListener('scroll', scrollHeader);
    document.addEventListener('DOMContentLoaded', onReloadHideHeader);
    window.addEventListener('resize', resizeScreen);
    document.addEventListener('keyup', onDocumentKeyUp);
    window.addEventListener('touchmove', getMobileWindowHeight);

    // Cleanup function to remove the event listener
    return () => {
      skipContent.removeEventListener('keyup', onSkipContentKeyUp);
      window.removeEventListener('scroll', scrollHeader);
      document.removeEventListener('DOMContentLoaded', onReloadHideHeader);
      window.removeEventListener('resize', resizeScreen);
      document.removeEventListener('keyup', onDocumentKeyUp);
      window.removeEventListener('touchmove', getMobileWindowHeight);

      sessionStorage.removeItem('ABTestPromotionBanner');
    };
  }, []);

  useEffect(() => {
    // Hide Military Personnel CTA outside the USA
    const navPersonnelTab = [
      ...document.querySelectorAll('.categories__list .items__list li'),
    ].filter(el =>
      el
        .querySelector('a')
        .href.includes('/specials/firefighter-military-police-savings/')
    );

    hideByRegion(['US'], [...navPersonnelTab], true);
  }, []);

  useEffect(() => {
    if (isWrapperActive || isWrapperActiveHide) {
      setPromoBannerVisible(false);
    } else if (!isWrapperActive && !isWrapperActiveHide) {
      setPromoBannerVisible(promoBannerExists);
    }
  }, [isWrapperActive]);

  const hrefHandler = e => {
    const href = e.target.getAttribute('href');

    if (href.trim().length < 1) {
      e.preventDefault();
    }
  };

  const handleActivePanel = isActive => {
    const headerWrapper = document.querySelector('.hm__wrapper');

    if (isActive) {
      menuHandler(false);
      headerWrapper.classList.remove('hidden');
    } else {
      menuHandler(true);
    }
  };

  const onAccountLoginClick = e => {
    e.preventDefault();

    handleActivePanel(isAccountsOpen);
    setAccountsOpen(!isAccountsOpen);

    if (!isMobile(window.innerWidth)) {
      closeCategories();
      closeBooking();
    }
  };

  const onCategoryLinkClick = (e, linkName) => {
    e.preventDefault();
    hrefHandler(e);

    const isActive = activeCat === linkName;

    closeAccounts();
    if (isActive) {
      menuHandler(false);
      closeCategories();
    } else {
      menuHandler(true);
      setActiveCat(linkName);
      closeBooking();

      const resortsItemLi = document.querySelectorAll(
        '.categories__items_resorts .items__list ul li'
      );

      for (const itemElem of resortsItemLi) {
        const elementA = itemElem.querySelector('a');
        const elementLabel = itemElem.querySelector('strong');

        if (elementLabel !== null) {
          const offsetRight = itemElem.offsetWidth - elementA.offsetWidth;

          elementLabel.style.right =
            offsetRight - elementLabel.offsetWidth - 5 + 'px';
        }
      }
      setMainNavScrollable(false);
    }
  };

  const onMobileToggleClick = () => {
    if (isMenuOpen) {
      menuHandler(false);

      closeBooking();
      closeSearch();
      closeAccounts();
      getMobileWindowHeight();
    } else {
      menuHandler(true);
    }
  };

  // Category back button
  const onBackButtonClick = e => {
    e.preventDefault();

    setTimeout(() => {
      closeCategories();
    }, 400);

    closeSearch();
    closeAccounts();
  };

  const toggleUserPanel = () => {
    setUserPanelOpen(!isUserPanelOpen);
    setMainNavScrollable(!isMainNavScrollable);
  };

  const toggleLangSelect = () => {
    setLanguageSelectorOpen(!isLanguageSelectorOpen);
    setMainNavScrollable(!isMainNavScrollable);
  };

  const onUserProfileClick = e => {
    e.preventDefault();

    if (isLanguageSelectorOpen) {
      toggleLangSelect();

      setTimeout(() => {
        toggleUserPanel();
      }, 400);
    } else {
      toggleUserPanel();
    }

    if (!userProfileRef.current.classList.contains('hide-item')) {
      setOutsideActive(true);
    }
  };

  // Booking
  const handleBookingClick = e => {
    e.preventDefault();
    if (isMobile(window.innerWidth)) {
      setMenuOpen(false);
      setSearchOpen(false);
    }

    handleActivePanel(isBookingOpen);
    setBookingOpen(!isBookingOpen);

    closeAccounts();
    closeCategories();
  };

  // Search
  const onSearchInputFocusIn = e => {
    e.preventDefault();
  };

  const onSearchLinkClick = e => {
    e.preventDefault();

    handleActivePanel(isSearchOpen);
    setSearchOpen(!isSearchOpen);
    searchInputRef.current.focus();

    if (!isMobile(window.innerWidth)) {
      closeBooking();
      closeCategories();
      closeAccounts();
    }

    if (isUserPanelOpen) {
      userProfileRef.current.click();
    }
  };

  const onSearchInputChange = e => {
    e.target.value.length > 0
      ? setSearchResetActive(true)
      : setSearchResetActive(false);
  };

  const onSearchResetClick = () => {
    searchInputRef.current.value = '';
    setSearchResetActive(false);
  };

  const onSearchCloseClick = e => {
    e.preventDefault();

    handleActivePanel(isSearchOpen);
    closeSearch();
    closeAccounts();
  };

  const redirectToSearch = () => {
    const searchTerm = encodeURI(searchInputRef.current.value);

    if (searchTerm.length > 0) {
      window.location.href = `${redirectUrl}${searchTerm}`;
    }
  };

  const onSearchBarKeyUp = e => {
    if (e.keyCode === 13) {
      redirectToSearch();
    }
  };

  const onLanguageSelClick = e => {
    e.preventDefault();

    if (isUserPanelOpen) {
      toggleUserPanel();

      setTimeout(() => {
        toggleLangSelect();
      }, 400);
    } else {
      toggleLangSelect();
    }
    setOutsideActive(true);
  };

  languageListRef.current?.querySelectorAll('li').forEach(item => {
    item.addEventListener('click', event => {
      event.preventDefault();
      document.cookie = `USER_LANGUAGE=${event.target.dataset.language}; Path=/; expires=Fri, 31 Dec 9999 23:59:59 GMT;`;
      window.location.href = item.href;
    });
  });

  // Click Outside The Open Menu
  const onOutsideClick = () => {
    menuHandler(false);
    if (isUserPanelOpen) {
      userProfileRef.current.click();
    }

    if (isLanguageSelectorOpen) {
      languageSelRef.current.click();
    }
    setAccountsOpen(false);
  };

  const onHeaderKeyUp = e => {
    if (e.keyCode === 27 && isMenuOpen) {
      menuHandler(false);
    }
  };

  const renderPromoBanner = () => {
    if (!isPromoBannerVisible || Object.keys(referral).length > 0) {
      return null;
    }

    return (
      <>
        <div
          id="js-promotional__banner"
          className={`promo-banner ${showPromoBanner ? 'show' : 'hidden'}`}
        >
          <div className="row">
            <div className="xs-12">
              <span>
                <a className="promo-banner__link" href={promoBanner[0]?.url}>
                  {promoBanner[0]?.title}
                  <i
                    role="presentation"
                    aria-label="icon"
                    className="ic ic-right-arrow"
                  />
                </a>
              </span>
            </div>
          </div>
        </div>
      </>
    );
  };

  const modalHandler = () => {
    bodyRef = document.querySelector('body');

    if (showChoiceModal) {
      if (isMobile(window.innerWidth) && isMenuOpen) {
        bodyRef.style.overflow = 'hidden';
      } else {
        bodyRef.style.overflow = '';
      }
    }
    setShowChoiceModal(!showChoiceModal);
  };

  const renderModal = () => {
    if (!isChoiceReferral) {
      return null;
    }

    const choiceCobrandPopupData = choiceCobrandData?.popup;

    return showChoiceModal ? (
      <>
        <Modal
          id={'choice-referral-v1__modal'}
          className="choice-referral"
          content={
            <div className="modal__wrapper">
              <div className="">
                <div className="row">
                  <div className="xs-12 xs-center">
                    <div className="logo__wrapper">
                      <ResolveImg
                        imgData={{
                          className: 'cobrand__logo',
                          src: choiceCobrandPopupData.logo,
                          alt: 'Choice Privileges Reward Logo',
                        }}
                      />
                    </div>
                  </div>
                  <div className="xs-12 xs-center">
                    <div className="content__wrapper">
                      <div className="content__description">
                        {choiceCobrandPopupData.description}
                      </div>
                      <button
                        onClick={() => modalHandler()}
                        type="button"
                        className={'continue-button san-submit'}
                      >
                        {choiceCobrandPopupData.buttonLabel}
                      </button>
                      <div className="terms-conditions">
                        <a
                          href={choiceCobrandPopupData.termsAndConditions.link}
                          target="_blank"
                        >
                          {choiceCobrandPopupData.termsAndConditions.label}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          }
          modalHandler={modalHandler}
        />
      </>
    ) : null;
  };

  const renderReferral = () => {
    return (
      <>
        {referral && Object.keys(referral).length > 0 ? (
          <div
            className={`hm__referral ${
              isChoiceReferral ? 'choice-referral' : ''
            }`}
            style={
              isChoiceReferral
                ? {
                    background: choiceCobrandData.backgroundColor,
                  }
                : null
            }
          >
            <div className="container no-gutters-sm-min-up">
              <div className="row">
                <div className="xs-12">
                  {isChoiceReferral ? (
                    <>
                      <span
                        className="referral__label"
                        style={{
                          fontSize: isTablet
                            ? choiceCobrandData.mobileLabelSize
                            : choiceCobrandData.labelSize,
                        }}
                      >
                        {choiceCobrandData.label}
                      </span>
                      <button
                        onClick={() => modalHandler()}
                        className="choicePrivilege__modal-button"
                      >
                        <i
                          role="presentation"
                          aria-label="icon"
                          className="ic ic-info"
                        />
                      </button>
                    </>
                  ) : (
                    <>
                      <span className="referral__label">Referred by:</span>
                      <span className="referral__info">
                        {referral.referrerName && `${referral.referrerName}, `}
                        {referral.agencyName}
                      </span>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </>
    );
  };

  const renderBooking = () => {
    return (
      <div className="xs-12 sm-3 md-2 hm__booking">
        <div className="row end-xs">
          <button
            ref={bookingButtonRef}
            className={`booking__btn ${
              isBookingOpen ? 'booking__btn--active' : ''
            }`}
            onClick={e => handleBookingClick(e)}
          >
            CHECK Rates<span>&amp; Availability</span>
          </button>

          <button
            ref={mobileToggleRef}
            id="js-menu-btn"
            className="hm__btn hidden-sm-min-up"
            onClick={onMobileToggleClick}
          >
            <i role="presentation" aria-label="icon" className="icon__bar" />
          </button>

          <div
            className={`booking__menu ${
              isBookingOpen ? 'booking__menu--active' : ''
            }`}
          >
            <div className="row middle-sm booking__column">
              <div className="booking__specific xs-12 sm-11 lg-12">
                <div id="js-header__qq">
                  <QQ
                    resortSelect={{
                      resortOptions: resorts,
                      labelText: 'Select Resort / Destination',
                      isResortRequired: true,
                      storeResortCode: true,
                    }}
                    className="booking__qq"
                    calendar={{
                      iconCaret: false,
                      iconCalendar: true,
                      monthsToDisplay: 2,
                      theme: 'dark',
                      minimumNights: 1,
                      alignment: 'center',
                      checkinDateName: 'checkin-date',
                      checkoutDateName: 'checkout-date',
                      defoCheckinDate: 'Check-In Date',
                      defoCheckoutDate: 'Check-Out Date',
                      onApplyDates: () => {},
                      onCloseCalendar: () => {},
                      onClearDates: () => {},
                      isDatesRequired: true,
                    }}
                    mainButton="Find Rates &amp; Availability"
                    bottomElement
                    onChange={() => {}}
                    horizontalSpecial
                    horizontalSpecialAlignment
                    noTabs
                    storeResortCode
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderUtils = () => {
    return (
      <div className="hm__utils col-sm-12">
        <ul className="utils__list">
          <li
            className={`utils__li utils__li${
              AccountsActive[0]?.subtitle || ''
            } js-sign-in-accounts--label`}
          >
            <a
              href=""
              id="accounts"
              className={`utils__link ${isLoggedIn ? 'hide-item' : ''}`}
              data-id="accounts-data"
              ref={accountsLoginRef}
              onClick={e => onAccountLoginClick(e)}
            />
            <div
              id="accounts-user"
              className={`utils__link ${!isLoggedIn ? 'hide-item' : ''} ${
                isUserPanelOpen ? 'open-menu' : ''
              }`}
              data-id="accounts-user-data"
              ref={userProfileRef}
              onClick={e => onUserProfileClick(e)}
            />
            <div
              id="js-accounts-user__content"
              className={`${isUserPanelOpen ? 'show-profile' : 'hide-profile'}`}
              ref={userPanelRef}
            />
          </li>

          <li className={`utils__li${searchActive[0].subtitle || ''}`}>
            <a
              href=""
              id="search"
              className="utils__link"
              ref={searchLinkRef}
              onClick={e => onSearchLinkClick(e)}
            >
              Search
            </a>
          </li>

          <li className={`utils__li ${languageDisp}`}>
            <a
              ref={languageSelRef}
              href=""
              id="language-selector"
              className={`utils__link ${!currentLang ? 'none' : ''} ${
                isLanguageSelectorOpen ? 'selector--open' : ''
              }`}
              onClick={e => onLanguageSelClick(e)}
            >
              <span className="lang-name">{currentLang}</span>
              <i
                className="ic ic-bottom-arrow-rd"
                role="presentation"
                aria-label="icon"
              />
            </a>
            <div
              ref={languageContainerRef}
              className={`lang-sel__container ${
                isLanguageSelectorOpen ? 'selector--open' : ''
              }`}
              id="lang-selector-container"
            >
              <div
                className="lang-sel__wrapper"
                id="lang-selector-list"
                ref={languageListRef}
              >
                <ul>
                  {languageOptions.map((language, index) => (
                    <li
                      key={index}
                      data-language={language.url || ''}
                      className={
                        currentLang === language.title ? 'active-lang' : ''
                      }
                    >
                      {language.title || ''}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </li>
        </ul>
      </div>
    );
  };

  const renderCategories = () => {
    return (
      <div className="hm__categories col-sm-12">
        <ul className="categories__list">
          {headerSections.map((link, index) => {
            const sanitizedLinkName = link.name.replace(/ |&/g, '');

            let specialsCategoryColumn = null;

            if (link.name === 'specials') {
              specialsCategoryColumn = link.column.find(
                col => col.label === 'specials-test'
              );
            }

            const sectionColumn = link.column[0];

            return (
              <li key={index}>
                {link.name === 'inclusions' ? (
                  <a className="categories__link--direct" href={link.linkUrl}>
                    {link.name}
                  </a>
                ) : (
                  <a
                    className={`categories__link ${
                      !isMobile(window.innerWidth) &&
                      activeCat === link.name &&
                      isMenuOpen
                        ? 'categories__link--active'
                        : ''
                    }`}
                    href={`#${sanitizedLinkName}`}
                    data-id={sanitizedLinkName}
                    onClick={e => onCategoryLinkClick(e, link.name)}
                  >
                    {link.name}
                  </a>
                )}

                <div
                  id={sanitizedLinkName}
                  className={`categories__items categories__items_${sanitizedLinkName} ${
                    activeCat === link.name ? 'categories__items--active' : ''
                  }`}
                >
                  <div className="row">
                    <div className="xs-12">
                      <div className="row sticky-btn hidden-sm-min-up">
                        <div className="xs-12">
                          <a
                            href=""
                            className="back__btn"
                            onClick={e => onBackButtonClick(e)}
                          >
                            <i
                              role="presentation"
                              aria-label="icon"
                              className="ic ic-right-arrow"
                            />
                            Main Menu
                          </a>
                        </div>
                      </div>

                      {['weddings & celebrations', 'inclusions'].includes(
                        link.name
                      ) ? (
                        <>
                          <div className="row start-sm categories__row">
                            <div
                              className={'xs-12 sm-11 lg-12 categories__col'}
                            >
                              {link.name === 'weddings & celebrations' && (
                                <a
                                  href={link.column[0].link}
                                  className="items__label"
                                  dangerouslySetInnerHTML={{
                                    __html: link.column[0].name || '',
                                  }}
                                />
                              )}
                              <div className="row">
                                <div
                                  className={`xs-12 ${
                                    link.column.length > 1
                                      ? link.name === 'inclusions'
                                        ? 'sm-8 lg-9'
                                        : 'sm-9 lg-8'
                                      : ''
                                  }`}
                                >
                                  {link.name === 'inclusions' && (
                                    <a
                                      href={link.column[0].link}
                                      className="items__label"
                                    >
                                      {link.column[0].name}
                                    </a>
                                  )}
                                  <ul className="items__list items__list_top">
                                    {link.column[0].itemList && (
                                      <div
                                        dangerouslySetInnerHTML={{
                                          __html: link.column[0].itemList,
                                        }}
                                      />
                                    )}
                                  </ul>
                                </div>
                                {link.column.length > 1 && (
                                  <div
                                    className={`xs-12 ${
                                      link.name === 'inclusions'
                                        ? 'sm-4 lg-3'
                                        : 'sm-3 lg-4'
                                    }`}
                                  >
                                    {link.name === 'inclusions' && (
                                      <a
                                        href={link.column[1].link}
                                        className="items__label"
                                      >
                                        {link.column[1].name}
                                      </a>
                                    )}
                                    <ul className="items__list items__list_bottom">
                                      {link.column[1].itemList && (
                                        <div
                                          dangerouslySetInnerHTML={{
                                            __html: link.column[0].itemList,
                                          }}
                                        />
                                      )}
                                    </ul>
                                  </div>
                                )}
                              </div>
                            </div>
                            {link.name === 'inclusions' && (
                              <div className="xs-12 sm-11 lg-12">
                                <div className="items__extra">
                                  <span className="extra__disclaimer">
                                    * Available At Additional Cost
                                  </span>
                                </div>
                              </div>
                            )}
                          </div>
                        </>
                      ) : (
                        <div className="row start-sm categories__row">
                          <div
                            className={`xs-12 ${
                              link.name === 'groups' || link.name === 'specials'
                                ? 'sm-5 lg-9'
                                : 'sm-11 lg-12'
                            } categories__col ${
                              link.name === 'groups' ? 'categories__groups' : ''
                            }`}
                          >
                            {link.name === 'resorts' ? (
                              <div className="row">
                                <div className="sm-4 lg-2 items__region">
                                  {link.column
                                    .slice(0, 3)
                                    .map((column, idx) => (
                                      <ul className="items__list" key={idx}>
                                        {column.link && column.label && (
                                          <li>
                                            <a
                                              href={column.link}
                                              className="list__link"
                                            >
                                              {column.label}
                                            </a>
                                          </li>
                                        )}
                                        {column.itemList && (
                                          <div
                                            dangerouslySetInnerHTML={{
                                              __html: column.itemList,
                                            }}
                                          />
                                        )}
                                      </ul>
                                    ))}
                                </div>
                                <div className="sm-4 lg-2 items__region">
                                  {link.column
                                    .slice(3, 8)
                                    .map((column, idx) => (
                                      <ul className="items__list" key={idx}>
                                        {column.link && column.label && (
                                          <li>
                                            <a
                                              href={column.link}
                                              className="list__link"
                                            >
                                              {column.label}
                                            </a>
                                          </li>
                                        )}
                                        {column.itemList && (
                                          <div
                                            dangerouslySetInnerHTML={{
                                              __html: column.itemList,
                                            }}
                                          />
                                        )}
                                      </ul>
                                    ))}
                                </div>
                              </div>
                            ) : link.name === 'specials' ? (
                              <>
                                <a
                                  href={sectionColumn.link}
                                  className="items__label"
                                >
                                  {sectionColumn.name}
                                </a>
                                <ABTastyWrapper
                                  name="specials-category-links"
                                  className="specials-category-links"
                                  fallback={
                                    <ul className="items__list items__list_top">
                                      {sectionColumn.itemList && (
                                        <div
                                          dangerouslySetInnerHTML={{
                                            __html: sectionColumn.itemList,
                                          }}
                                        />
                                      )}
                                    </ul>
                                  }
                                >
                                  <ul className="items__list items__list_top">
                                    {specialsCategoryColumn && (
                                      <div
                                        dangerouslySetInnerHTML={{
                                          __html:
                                            specialsCategoryColumn?.itemList,
                                        }}
                                      />
                                    )}
                                  </ul>
                                </ABTastyWrapper>
                              </>
                            ) : (
                              link.column.map((column, idx) => (
                                <React.Fragment key={idx}>
                                  {link.name === 'groups' && (
                                    <a
                                      href={column.link}
                                      className="items__label"
                                    >
                                      {column.name}
                                    </a>
                                  )}
                                  {link.name === 'more' && (
                                    <span className="items__label">
                                      {column.name}
                                    </span>
                                  )}
                                  {link.name === 'more' ? (
                                    <ul className="items__list items__list_top items__list_more">
                                      {column.link && column.label && (
                                        <li>
                                          <a
                                            href={column.link}
                                            className="list__link"
                                          >
                                            {column.label}
                                          </a>
                                        </li>
                                      )}
                                      {column.itemList && (
                                        <div
                                          dangerouslySetInnerHTML={{
                                            __html: column.itemList,
                                          }}
                                        />
                                      )}
                                    </ul>
                                  ) : (
                                    <ul className="items__list items__list_top">
                                      {column.itemList && (
                                        <div
                                          dangerouslySetInnerHTML={{
                                            __html: column.itemList,
                                          }}
                                        />
                                      )}
                                    </ul>
                                  )}
                                </React.Fragment>
                              ))
                            )}
                          </div>

                          {link.name === 'groups' && (
                            <div className="xs-12 sm-6 lg-3 categories__extra-col">
                              <div className="items__extra">
                                <a href="tel:18887263257">
                                  <p className="extra__contact">
                                    groups direct line
                                    <span>1-800-327-1991 (EXT 6172)</span>
                                  </p>
                                </a>
                              </div>
                            </div>
                          )}

                          {link.name === 'specials' && (
                            <div className="xs-12 sm-6 lg-3 categories__extra-col">
                              <div className="items__extra">
                                <div className="extra__sale">
                                  {currentPromotion?.titleImage?.url ? (
                                    <ResolveImg
                                      imgData={{
                                        className: 'sale__logo',
                                        src: currentPromotion.titleImage.url,
                                        alt: 'Sale logo image',
                                      }}
                                    />
                                  ) : (
                                    <>
                                      <span className="sale__span">
                                        Escape to
                                      </span>
                                      <i
                                        className={`ic ${link.linkLabel} sale__icon`}
                                      />
                                    </>
                                  )}
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      )}

                      <div className="row row__cta">
                        <div className="hm__cta xs-12">
                          {['resorts', 'inclusions', 'specials'].includes(
                            link.name
                          ) && (
                            <>
                              {link.name === 'specials' &&
                              currentPromotion?.titleImage?.url ? (
                                <a
                                  className="cta__a cta__sale"
                                  href={currentPromotion.URL}
                                >
                                  <span className="cta__label">
                                    {currentPromotion.additional?.json?.cta ||
                                      ''}
                                  </span>
                                  <i
                                    role="presentation"
                                    aria-label="icon"
                                    className="cta__icon ic ic-right-arrow"
                                  />
                                </a>
                              ) : (
                                <>
                                  {link.name === 'resorts' ? (
                                    <>
                                      <span className="cta__title">
                                        {link.label || ''}
                                      </span>
                                      <a
                                        className="cta__a"
                                        href={link.linkUrl || ''}
                                      >
                                        <span className="cta__label">
                                          {link.linkLabel || ''}
                                        </span>
                                        <i
                                          role="presentation"
                                          aria-label="icon"
                                          className="cta__icon ic ic-right-arrow"
                                        />
                                      </a>
                                    </>
                                  ) : (
                                    <a
                                      className="cta__a"
                                      href={link.linkUrl || ''}
                                    >
                                      <span className="cta__label">
                                        {link.label || ''}
                                      </span>
                                      <i
                                        role="presentation"
                                        aria-label="icon"
                                        className="cta__icon ic ic-right-arrow"
                                      />
                                    </a>
                                  )}
                                </>
                              )}
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    );
  };

  const renderMenu = () => {
    return (
      <div
        ref={menuRef}
        className={`xs-12 sm-9 md-10 flx start-xs end-sm hm__menu ${
          isMenuOpen ? 'hm__menu--active' : ''
        } ${
          isMobile(window.innerWidth) &&
          (activeCat || isAccountsOpen || isSearchOpen) &&
          isMenuOpen
            ? 'hm__menu--swiped'
            : ''
        } ${isMainNavScrollable ? 'main-nav-scrollable' : ''}`}
      >
        <nav className="row column-reverse bottom-sm" aria-label="Site Menu">
          {renderUtils()}
          {renderCategories()}
        </nav>
      </div>
    );
  };

  const renderAccounts = () => {
    return (
      <div
        className={`hm__accounts ${
          isAccountsOpen ? 'hm__accounts--active' : ''
        } ${isAccountsScrolling ? 'scrolling' : ''}`}
        ref={accountsPanelRef}
      >
        <div className="accounts__container container no-gutters-sm-min-up">
          <div className="row">
            <div className="xs-12 no-gutters-xs-min-up">
              <div className="row hidden-sm-min-up">
                <div className="xs-12">
                  <a
                    href=""
                    className="back__btn-accounts"
                    onClick={e => onBackButtonClick(e)}
                  >
                    <i
                      role="presentation"
                      aria-label="icon"
                      className="ic ic-right-arrow"
                    />
                    Main Menu
                  </a>
                </div>
              </div>

              <div id="js-sign-in-accounts">
                <GcvFormik
                  loginHandler={setLoggedIn}
                  accountsInformation={accountsInformation}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderSearch = () => {
    return (
      <div
        className={`hm__search ${isSearchOpen ? 'hm__search--active' : ''}`}
        ref={searchPanelRef}
      >
        <div className="search__container container no-gutters-sm-min-up">
          <div className="row">
            <div className="xs-12 no-gutters-xs-min-up">
              <div className="row hidden-sm-min-up">
                <div className="xs-12">
                  <a
                    href=""
                    className="back__btn"
                    onClick={e => onBackButtonClick(e)}
                  >
                    <i
                      role="presentation"
                      aria-label="icon"
                      className="ic ic-right-arrow"
                    />
                    Main Menu
                  </a>
                </div>
              </div>

              <div className="row middle-xs center-xs">
                <div className="xs-12 sm-9 md-8">
                  <div className="search__wrapper">
                    <input
                      name="search-bar"
                      className="search__bar"
                      placeholder="Type anything to search Sandals…"
                      ref={searchInputRef}
                      onFocus={e => onSearchInputFocusIn(e)}
                      onChange={e => onSearchInputChange(e)}
                      onKeyUp={e => onSearchBarKeyUp(e)}
                    />
                    <button
                      className={`search__reset ${
                        isSearchResetActive ? 'search__reset--active' : ''
                      }`}
                      ref={searchResetRef}
                      onClick={onSearchResetClick}
                    >
                      <i
                        role="presentation"
                        aria-label="icon"
                        className="reset__icon ic ic-close-narrow"
                      />
                    </button>
                    <button
                      className="search__button"
                      ref={searchBtnRef}
                      onClick={redirectToSearch}
                    >
                      <i
                        role="presentation"
                        aria-label="icon"
                        className="ic ic-search"
                      />
                    </button>
                  </div>
                </div>
                <button
                  className="search__close hidden-sm-min-down"
                  ref={searchCloseButtonRef}
                  onClick={e => onSearchCloseClick(e)}
                >
                  <i
                    role="presentation"
                    aria-label="icon"
                    className="ic ic-close-narrow"
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <header
        ref={headerRef}
        id="js-header"
        className={`header_default header-v1 ${
          isMenuOpen ? 'header--open' : ''
        }`}
        onKeyUp={e => onHeaderKeyUp(e)}
      >
        {renderPromoBanner()}
        {renderReferral()}
        {renderModal()}
        <div
          id="js-hm__backdrop"
          className={`hm__backdrop
          ${
            !isMobile(window.innerWidth) && isSearchOpen
              ? 'hm__backdrop_search'
              : ''
          } ${
            !isMobile(window.innerWidth) && isAccountsOpen
              ? 'hm__backdrop_accounts'
              : ''
          }`}
        />
        <div
          id="js-outside__click"
          className={`${isOutsideActive ? 'hm__outside' : ''}`}
          ref={outsideClickRef}
          onClick={onOutsideClick}
        />
        {renderSearch()}
        {renderAccounts()}
        <div
          className={`hm__wrapper ${headerSolidColorPage} ${
            isWrapperActive ? 'hm__wrapper--active' : ''
          } ${isWrapperActiveHide ? 'hm__wrapper--active--hide' : ''}`}
          ref={headerWrapperRef}
        >
          <div className="hm__container container no-gutters-sm-min-up">
            <div className="row middle-xs hm__row">
              <div className="xs-4 sm-2 hm__col_left">
                <a className="hm__logo" href="/">
                  <i
                    role="presentation"
                    aria-label="icon"
                    className="ic ic-sandals-logo-icon"
                  />
                </a>
              </div>
              <div className="xs-8 sm-10 hm__col_right">
                <div className="row row-reverse middle-sm top-lg">
                  {renderMenu()}
                  {renderBooking()}
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
