import React, { useState, useEffect } from 'react';
import m from 'moment';
import Additional from './additional';
import Calendar from './calendar';
import Countdown from './countdown';
import DiscountOffers from './discountOffers';
import MainButton from './mainButton';
import RedBanner from './redBanner';
import ResortSelect from './resortSelect';
import TopBanner from './topBanner';
import ViewAllOffersBtn from './viewAllOffersBtn';
import SpecialsCarousel from './specialsCarousel';
import WithFlightsElement from './withFlightsElement';
import WithPricesElement from './withPricesElement';
import FlightsElement from './flightsElement';
import ResortHeader from './resortHeader';
import useWindowDimensions from '~/js/modules/hooks/useWindowDimensions';
import Dropdown from '~/js/components/global/dropdown/Dropdown';
import {
  numberOfAdults,
  numberOfAdultsShorten,
} from '~/js/components/resorts/rooms/utils/roomFilters';
import globalConfig from '../../../modules/global';

const RoomsQQ = props => {
  const { DEFAULTS } = globalConfig;
  const {
    resortHeader = null,
    topBanner = null,
    countdown = {
      endDate: null,
      theme: 'light',
      specials: [],
      counterAlwaysVisible: false,
    },
    redBanner = null,
    styledCustomClass = '',
    specials = [],
    additional = null,
    activeSale = {},
    discountOffers = {
      leftText: '',
      percentage: 0,
      rightText: '',
      linkUrl: '',
      linkText: '',
    },
    mainButton = null,
    buttonAction = null,
    targetBlank = false,
    customCallback = null,
    onInputClick = null,
    viewAllOffersBtn = false,
    bottomElement = null,
    calendar = {
      defaultText: '',
      iconCaret: false,
      iconCalendar: false,

      monthsToDisplay: 0,
      theme: 'light',
      checkinDateName: '',
      checkoutDateName: '',
      minDate: DEFAULTS.minDate,
      maxDate: DEFAULTS.maxDate,
      minimumNights: 1,
      alignment: 'right',
      portalClass: 'default__portal',
      onApplyDates: () => {},
      onCloseCalendar: () => {},
      onClearDates: () => {},
      renderDayContents: null,
      blockedDays: () => {},
      availableDaysMap: new Map(),
      hideOnCheckout: false,
      stickyDayHeader: true,
    },
    resortSelect = {
      resortOptions: [],
      labelText: '',
      isResortRequired: false,
    },
    saveVacationData,
    setCustomData = null,
    vacationData,
    bigCounter,
    glass,
    alignment = 'vertical',
    resetQQ = false,
    withFlights = false,
    collapsibleBanner = false,
    withFlightsId = [''],
    adultsSorter = false,
    isOcjAdditionalLabel = true,
    searchCriteriaAdditionalLabel = false,
  } = props;
  const { endDate, theme: countDownTheme, counterAlwaysVisible } = countdown;
  const { leftText, percentage, rightText, linkUrl, linkText, icon } =
    discountOffers;
  const { resortOptions, labelText, isResortRequired } = resortSelect;
  const {
    defaultText,
    iconCaret,
    iconCalendar,
    saveInputDate,
    monthsToDisplay,
    theme: calendarTheme,
    checkinDateName,
    checkoutDateName,
    customSingleInputCheckDateLabel = null,
    inputCheckDateVerticalDivider = false,
    minDate,
    maxDate,
    minimumNights,
    alignment: calendarAlign = 'right',
    portalClass,
    onApplyDates,
    onClearDates,
    onCloseCalendar,
    isDatesRequired = false,
    customDateFormat = '',
    renderDayContents,
    blockedDays,
    availableDaysMap,
    hideOnCheckout = false,
    stickyDayHeader = true,
  } = calendar;
  const { width } = useWindowDimensions();
  const isDesktop = width >= 768;

  const specialsLen = specials.length;
  const hasSpecials = specialsLen > 0;
  const hasActiveSale = Object.keys(activeSale).length > 0;
  const countdownSlideIndex = hasActiveSale ? 1 : 0;
  const [specialsVisible, setSpecialsVisible] = useState(false);
  const [carouselItemId, setCarouselItemId] = useState(0);
  const [withFlightsVisible, setWithFlightsVisible] = useState(false);
  const [searchCriteria, setSearchCriteria] = useState('');
  const [airportArrivingTo, setAirportArrivingTo] = useState('ocj');

  const [counterVisible, setCounterVisible] = useState(false);
  const [widthToggle, setWidthToggle] = useState(false);
  const [redBannerBackground, setRedBannerBackground] = useState(false);

  const [adultNumber] = useState(numberOfAdults[0].value);

  const arrivalGatewayResorts = ['SGO', 'BRP', 'SDR'];

  const handleSpecialsRedirect = () => {
    window.location.href = activeSale.URL;
  };

  const resetElement = () => {
    setSpecialsVisible(false);
    setCarouselItemId(0);
  };

  const getValidDates = () => {
    const checkIn = m(vacationData.checkInDate, 'MM-DD-YYYY').day();
    const checkOut = m(vacationData.checkOutDate, 'MM-DD-YYYY').day();

    const isValidDates =
      (checkIn === 3 || checkIn === 6) && (checkOut === 3 || checkOut === 6);

    return isValidDates;
  };

  const selectGateway = ({ value: gateway }) => {
    if (setCustomData) {
      setCustomData(prevState => ({
        ...prevState,
        gateway: gateway,
      }));
    } else {
      saveVacationData({ gateway });
    }
  };

  const toggleWithFlights = () => {
    const seatType = withFlightsVisible ? '' : 'economy';

    setWithFlightsVisible(!withFlightsVisible);

    if (setCustomData) {
      setCustomData(prevState => ({
        ...prevState,
        withFlightsVisible: !withFlightsVisible,
        gateway: '',
        seatType: seatType,
        arrivalGateway:
          arrivalGatewayResorts.indexOf(vacationData.rstCode) !== -1 &&
          getValidDates() &&
          !withFlightsVisible
            ? 'ocj'
            : '',
      }));
    } else {
      saveVacationData({
        gateway: '',
        seatType,
        arrivalGateway:
          arrivalGatewayResorts.indexOf(vacationData.rstCode) !== -1 &&
          getValidDates() &&
          !withFlightsVisible
            ? 'ocj'
            : '',
      });
    }

    setAirportArrivingTo(
      arrivalGatewayResorts.indexOf(vacationData.rstCode) !== -1 &&
        getValidDates() &&
        !withFlightsVisible
        ? 'ocj'
        : ''
    );
  };
  const handleSearchCriteriaChange = e => {
    const { currentTarget } = e;
    const { value: seatType } = currentTarget;

    setSearchCriteria(seatType);
    saveVacationData({ seatType });
  };

  const handleAirportArrivingTo = e => {
    const { currentTarget } = e;
    const { value: arrivalGateway } = currentTarget;

    setAirportArrivingTo(arrivalGateway);
    saveVacationData({ arrivalGateway });
  };

  const glassClass = glass ? 'glass' : 'solid';

  useEffect(() => {
    if (resetQQ) {
      resetElement();

      saveVacationData({
        rstCode: '',
        checkInDate: '',
        checkOutDate: '',
        gateway: '',
        seatType: 'economy',
        arrivalGateway: '',
      });
    }
  }, [resetQQ]);

  useEffect(() => {
    if (isDesktop) {
      setCounterVisible(true);
      setRedBannerBackground(false);
    } else {
      setWidthToggle(true);
    }
  }, [width]);

  useEffect(() => {
    if (!isDesktop) {
      setCounterVisible(false);
      setWidthToggle(false);
      setSpecialsVisible(false);
      setRedBannerBackground(false);
    }
  }, [widthToggle]);

  return (
    <div
      className={`vertical-qq qq-background-${glassClass} ${styledCustomClass}`}
    >
      {hasSpecials && specialsVisible && (
        <button
          className={`qq-close-btn ${
            carouselItemId > countdownSlideIndex ? 'adjust' : ''
          }`}
          onClick={resetElement}
        >
          &times;
        </button>
      )}
      {resortHeader && <ResortHeader {...resortHeader} />}
      {topBanner && <TopBanner>{topBanner}</TopBanner>}
      {endDate && (
        <Countdown
          endDate={endDate}
          theme={countDownTheme}
          bigCounter={bigCounter}
          carouselItemId={carouselItemId}
          counterAlwaysVisible={counterAlwaysVisible}
          counterVisible={counterVisible}
          hasActiveSale={hasActiveSale}
        />
      )}
      {redBanner && (
        <RedBanner
          redBanner={redBanner}
          hasSpecials={hasSpecials}
          carouselItemId={carouselItemId}
          specialsVisible={specialsVisible}
          handleSpecialsRedirect={() => handleSpecialsRedirect()}
          redBannerBackground={redBannerBackground}
          collapsibleBanner={collapsibleBanner}
        />
      )}
      {hasSpecials && (
        <SpecialsCarousel
          specials={specials}
          specialsLen={specialsLen}
          visible={specialsVisible}
          setCarouselItemId={setCarouselItemId}
          carouselItemId={carouselItemId}
        />
      )}
      {additional && <Additional>{additional}</Additional>}
      {percentage > 0 && (
        <div className="row center-xs middle-xs qq-element-wrap">
          <div className="xs-12">
            <DiscountOffers
              leftText={leftText}
              percentage={percentage}
              rightText={rightText}
              linkUrl={linkUrl}
              linkText={linkText}
              icon={icon}
            />
          </div>
        </div>
      )}
      {resortOptions.length > 0 && (
        <div className="row center-xs middle-xs qq-element-wrap">
          <div className="xs-12">
            <ResortSelect
              alignment={alignment}
              resortOptions={resortOptions}
              labelText={labelText}
              vacationData={vacationData}
              saveVacationData={saveVacationData}
              onInputClick={onInputClick}
              resetInput={resetQQ}
              setAirportArrivingTo={setAirportArrivingTo}
              isRequired={isResortRequired}
              withFlightsVisible={withFlightsVisible}
              arrivalGatewayResorts={arrivalGatewayResorts}
            />
          </div>
        </div>
      )}

      <div
        className={`row middle-xs qq-element-wrap qq-styled-calendar ${
          monthsToDisplay > 0 ? '' : 'hidden-xs-min-up'
        }`}
      >
        <div
          className={
            styledCustomClass
              ? 'styled__calendar xs-12'
              : 'styled__calendar xs-12 sm-6'
          }
        >
          <Calendar
            defaultText={defaultText}
            iconCaret={iconCaret}
            iconCalendar={iconCalendar}
            saveInputDate={saveInputDate}
            theme={calendarTheme}
            checkinDateName={checkinDateName}
            checkoutDateName={checkoutDateName}
            customSingleInputCheckDateLabel={customSingleInputCheckDateLabel}
            inputCheckDateVerticalDivider={inputCheckDateVerticalDivider}
            minDate={minDate}
            maxDate={maxDate}
            minimumNights={minimumNights}
            monthsToDisplay={monthsToDisplay}
            vacationData={vacationData}
            saveVacationData={saveVacationData}
            alignment={calendarAlign}
            onInputClick={onInputClick}
            portalClass={portalClass}
            resetInput={resetQQ}
            onApplyDates={onApplyDates}
            onClearDates={onClearDates}
            onCloseCalendar={onCloseCalendar}
            styledCustomClass={styledCustomClass}
            resortOptions={resortOptions}
            withFlightsVisible={withFlightsVisible}
            isRequired={isDatesRequired}
            setAirportArrivingTo={setAirportArrivingTo}
            arrivalGatewayResorts={arrivalGatewayResorts}
            customDateFormat={customDateFormat}
            renderDayContents={renderDayContents}
            blockedDays={blockedDays}
            availableDaysMap={availableDaysMap}
            hideOnCheckout={hideOnCheckout}
            stickyDayHeader={stickyDayHeader}
          />
        </div>

        {adultsSorter && (
          <div className="rooms__sorter rooms__adult xs-12 sm-3">
            <Dropdown
              readOnly
              activeCategory={adultNumber}
              name="rooms-adult"
              options={numberOfAdults}
              dropdownHandler={() => {}}
              className="sorter"
            />
          </div>
        )}

        {withFlights && (
          <div className="with-flights-vertical qq-element-wrap xs-12 sm-3">
            <WithFlightsElement
              withFlightsId={withFlightsId}
              value={withFlightsVisible}
              onClick={toggleWithFlights}
              labelCopy="Include Roundtrip Flights"
            />
          </div>
        )}

        {styledCustomClass && (
          <div className="xs-4 qq-styled-mobile-button">
            <MainButton
              copy={mainButton}
              bottomElement={bottomElement}
              vacationData={vacationData}
              buttonAction={buttonAction}
              targetBlank={targetBlank}
              customCallback={customCallback}
              resortOptions={resortOptions}
              withFlightsVisible={withFlightsVisible}
              isResortRequired={isResortRequired}
              isDatesRequired={isDatesRequired}
            />
          </div>
        )}
      </div>
      {withFlights && withFlightsVisible && (
        <div className="row center-xs middle-xs with-flights-vertical qq-element-wrap">
          <div className="xs-12">
            <div className="row">
              <FlightsElement
                searchCriteria={searchCriteria}
                handleSearchCriteriaChange={handleSearchCriteriaChange}
                selectGateway={selectGateway}
                resetInput={resetQQ}
                flightsElementClassName="xs-12 sm-4 flights-departing"
                searchCriteriaClassName="xs-12 sm-4 flight-search qq-resort-select"
                airportArrivingToClassName="xs-12 sm-4 airport-arriving qq-resort-select"
                handleAirportArrivingTo={handleAirportArrivingTo}
                airportArrivingTo={airportArrivingTo}
                setAirportArrivingTo={setAirportArrivingTo}
                vacationData={vacationData}
                arrivalGatewayResorts={arrivalGatewayResorts}
                isRequired
                isOcjAdditionalLabel={isOcjAdditionalLabel}
                searchCriteriaAdditionalLabel={searchCriteriaAdditionalLabel}
              />
            </div>
          </div>
        </div>
      )}
      {mainButton && (
        <div className="row center-xs middle-xs qq-element-wrap qq-styled-main-button">
          <div className="xs-12">
            <MainButton
              copy={mainButton}
              bottomElement={bottomElement}
              vacationData={vacationData}
              buttonAction={buttonAction}
              targetBlank={targetBlank}
              customCallback={customCallback}
              resortOptions={resortOptions}
              withFlightsVisible={withFlightsVisible}
              isResortRequired={isResortRequired}
              isDatesRequired={isDatesRequired}
            />
          </div>
        </div>
      )}
      {viewAllOffersBtn && <ViewAllOffersBtn />}
    </div>
  );
};

const StandaloneButtonQQ = props => {
  const { DEFAULTS } = globalConfig;
  const {
    styledCustomClass = '',
    mainButton = null,
    buttonAction = null,
    targetBlank = false,
    customCallback = null,
    bottomElement = null,
    calendar = {
      defaultText: '',
      iconCaret: false,
      iconCalendar: false,

      monthsToDisplay: 0,
      theme: 'light',
      checkinDateName: '',
      checkoutDateName: '',
      minDate: DEFAULTS.minDate,
      maxDate: DEFAULTS.maxDate,
      minimumNights: 1,
      alignment: 'right',
      portalClass: 'default__portal',
      onApplyDates: () => {},
      onCloseCalendar: () => {},
      onClearDates: () => {},
      renderDayContents: null,
      blockedDays: () => {},
      availableDaysMap: new Map(),
      hideOnCheckout: false,
      stickyDayHeader: true,
    },
    resortSelect = {
      resortOptions: [],
      labelText: '',
      isResortRequired: false,
    },
    vacationData,
    glass,
    QQData = null,
    categoryCode = '',
  } = props;
  const { resortOptions, isResortRequired } = resortSelect;
  const { isDatesRequired = false } = calendar;
  const { width } = useWindowDimensions();
  const isDesktop = width >= 768;

  const { withFlightsVisible, QQRef } = QQData || {};
  const [widthToggle, setWidthToggle] = useState(false);

  const glassClass = glass ? 'glass' : 'solid';

  useEffect(() => {
    if (!isDesktop) {
      setWidthToggle(true);
    }
  }, [width]);

  useEffect(() => {
    if (!isDesktop) {
      setWidthToggle(false);
    }
  }, [widthToggle]);

  return (
    <div
      className={`vertical-qq qq-background-${glassClass} ${styledCustomClass}`}
    >
      {mainButton && (
        <div className="row center-xs middle-xs qq-element-wrap qq-styled-main-button">
          <div className="xs-12">
            <MainButton
              copy={mainButton}
              bottomElement={bottomElement}
              vacationData={vacationData}
              buttonAction={buttonAction}
              targetBlank={targetBlank}
              customCallback={customCallback}
              resortOptions={resortOptions}
              withFlightsVisible={withFlightsVisible}
              isResortRequired={isResortRequired}
              isDatesRequired={isDatesRequired}
              QQData={QQData} // Standalone button needs for QQ data to be sent
              QQRef={QQRef} // Standalone button needs a reference for the main QQ element
              categoryCodeCard={categoryCode}
            />
          </div>
        </div>
      )}
    </div>
  );
};

const PricesQQ = props => {
  const { DEFAULTS } = globalConfig;
  const {
    resortHeader = null,
    topBanner = null,
    countdown = {
      endDate: null,
      theme: 'light',
      specials: [],
      counterAlwaysVisible: false,
    },
    redBanner = null,
    styledCustomClass = '',
    specials = [],
    additional = null,
    activeSale = {},
    discountOffers = {
      leftText: '',
      percentage: 0,
      rightText: '',
      linkUrl: '',
      linkText: '',
    },
    mainButton = null,
    buttonAction = null,
    targetBlank = false,
    customCallback = null,
    onInputClick = null,
    viewAllOffersBtn = false,
    bottomElement = null,
    calendar = {
      defaultText: '',
      iconCaret: false,
      iconCalendar: false,

      monthsToDisplay: 0,
      theme: 'light',
      checkinDateName: '',
      checkoutDateName: '',
      minDate: DEFAULTS.minDate,
      maxDate: DEFAULTS.maxDate,
      minimumNights: 1,
      alignment: 'right',
      portalClass: 'default__portal',
      onApplyDates: () => {},
      onCloseCalendar: () => {},
      onClearDates: () => {},
      renderDayContents: null,
      blockedDays: () => {},
      availableDaysMap: new Map(),
      hideOnCheckout: false,
      stickyDayHeader: true,
    },
    resortSelect = {
      resortOptions: [],
      labelText: '',
      isResortRequired: false,
    },
    saveVacationData,
    vacationData,
    bigCounter,
    glass,
    alignment = 'vertical',
    resetQQ = false,
    withFlights = false,
    collapsibleBanner = false,
    withFlightsId = [''],
    adultsSorter = false,
    isOcjAdditionalLabel = true,
    roomPrice = 0,
    numberOfNights = 0,
    isOnlyTotal = false,
  } = props;
  const { endDate, theme: countDownTheme, counterAlwaysVisible } = countdown;
  const { leftText, percentage, rightText, linkUrl, linkText, icon } =
    discountOffers;
  const { resortOptions, labelText, isResortRequired } = resortSelect;
  const {
    defaultText,
    iconCaret,
    iconCalendar,
    saveInputDate,
    monthsToDisplay,
    theme: calendarTheme,
    checkinDateName,
    checkoutDateName,
    customSingleInputCheckDateLabel = null,
    inputCheckDateVerticalDivider = false,
    minDate,
    maxDate,
    minimumNights,
    alignment: calendarAlign = 'right',
    portalClass,
    onApplyDates,
    onClearDates,
    onCloseCalendar,
    isDatesRequired = false,
    customDateFormat = '',
    renderDayContents,
    blockedDays,
    availableDaysMap,
    hideOnCheckout = false,
    stickyDayHeader = true,
    defoCheckinDate = 'Check-In',
    defoCheckoutDate = 'Check-Out',
  } = calendar;
  const { width } = useWindowDimensions();
  const isDesktop = width >= 768;

  const specialsLen = specials.length;
  const hasSpecials = specialsLen > 0;
  const hasActiveSale = Object.keys(activeSale).length > 0;
  const countdownSlideIndex = hasActiveSale ? 1 : 0;
  const [specialsVisible, setSpecialsVisible] = useState(false);
  const [carouselItemId, setCarouselItemId] = useState(0);
  const [withFlightsVisible, setWithFlightsVisible] = useState(false);
  const [searchCriteria, setSearchCriteria] = useState('');
  const [airportArrivingTo, setAirportArrivingTo] = useState('ocj');

  const [counterVisible, setCounterVisible] = useState(false);
  const [widthToggle, setWidthToggle] = useState(false);
  const [redBannerBackground, setRedBannerBackground] = useState(false);

  const [adultNumber] = useState(numberOfAdults[0].value);

  const arrivalGatewayResorts = ['SGO', 'BRP', 'SDR'];

  const handleSpecialsRedirect = () => {
    window.location.href = activeSale.URL;
  };

  const resetElement = () => {
    setSpecialsVisible(false);
    setCarouselItemId(0);
  };

  const getValidDates = () => {
    const checkIn = m(vacationData.checkInDate, 'MM-DD-YYYY').day();
    const checkOut = m(vacationData.checkOutDate, 'MM-DD-YYYY').day();

    const isValidDates =
      (checkIn === 3 || checkIn === 6) && (checkOut === 3 || checkOut === 6);

    return isValidDates;
  };

  const toggleWithFlights = () => {
    const seatType = withFlightsVisible ? '' : 'economy';

    setWithFlightsVisible(!withFlightsVisible);
    saveVacationData({ gateway: '', seatType });

    setAirportArrivingTo(
      arrivalGatewayResorts.indexOf(vacationData.rstCode) !== -1 &&
        getValidDates() &&
        !withFlightsVisible
        ? 'ocj'
        : ''
    );
    saveVacationData({
      arrivalGateway:
        arrivalGatewayResorts.indexOf(vacationData.rstCode) !== -1 &&
        getValidDates() &&
        !withFlightsVisible
          ? 'ocj'
          : '',
    });
  };
  const handleSearchCriteriaChange = e => {
    const { currentTarget } = e;
    const { value: seatType } = currentTarget;

    setSearchCriteria(seatType);
    saveVacationData({ seatType });
  };

  const handleAirportArrivingTo = e => {
    const { currentTarget } = e;
    const { value: arrivalGateway } = currentTarget;

    setAirportArrivingTo(arrivalGateway);
    saveVacationData({ arrivalGateway });
  };

  const selectGateway = ({ value: gateway }) => {
    saveVacationData({ gateway });
  };
  const glassClass = glass ? 'glass' : 'solid';

  useEffect(() => {
    if (resetQQ) {
      resetElement();

      saveVacationData({
        rstCode: '',
        checkInDate: '',
        checkOutDate: '',
        gateway: '',
        seatType: 'economy',
        arrivalGateway: '',
      });
    }
  }, [resetQQ]);

  useEffect(() => {
    if (isDesktop) {
      setCounterVisible(true);
      setRedBannerBackground(false);
    } else {
      setWidthToggle(true);
    }
  }, [width]);

  useEffect(() => {
    if (!isDesktop) {
      setCounterVisible(false);
      setWidthToggle(false);
      setSpecialsVisible(false);
      setRedBannerBackground(false);
    }
  }, [widthToggle]);

  return (
    <div
      className={`vertical-qq qq-background-${glassClass} ${styledCustomClass}`}
    >
      {hasSpecials && specialsVisible && (
        <button
          className={`qq-close-btn ${
            carouselItemId > countdownSlideIndex ? 'adjust' : ''
          }`}
          onClick={resetElement}
        >
          &times;
        </button>
      )}
      {resortHeader && <ResortHeader {...resortHeader} />}
      {topBanner && <TopBanner>{topBanner}</TopBanner>}
      {endDate && (
        <Countdown
          endDate={endDate}
          theme={countDownTheme}
          bigCounter={bigCounter}
          carouselItemId={carouselItemId}
          counterAlwaysVisible={counterAlwaysVisible}
          counterVisible={counterVisible}
          hasActiveSale={hasActiveSale}
        />
      )}
      {redBanner && (
        <RedBanner
          redBanner={redBanner}
          hasSpecials={hasSpecials}
          carouselItemId={carouselItemId}
          specialsVisible={specialsVisible}
          handleSpecialsRedirect={() => handleSpecialsRedirect()}
          redBannerBackground={redBannerBackground}
          collapsibleBanner={collapsibleBanner}
        />
      )}
      {hasSpecials && (
        <SpecialsCarousel
          specials={specials}
          specialsLen={specialsLen}
          visible={specialsVisible}
          setCarouselItemId={setCarouselItemId}
          carouselItemId={carouselItemId}
        />
      )}
      {additional && <Additional>{additional}</Additional>}
      {percentage > 0 && (
        <div className="row center-xs middle-xs qq-element-wrap">
          <div className="xs-12">
            <DiscountOffers
              leftText={leftText}
              percentage={percentage}
              rightText={rightText}
              linkUrl={linkUrl}
              linkText={linkText}
              icon={icon}
            />
          </div>
        </div>
      )}
      {resortOptions.length > 0 && (
        <div className="row center-xs middle-xs qq-element-wrap">
          <div className="xs-12">
            <ResortSelect
              alignment={alignment}
              resortOptions={resortOptions}
              labelText={labelText}
              vacationData={vacationData}
              saveVacationData={saveVacationData}
              onInputClick={onInputClick}
              resetInput={resetQQ}
              setAirportArrivingTo={setAirportArrivingTo}
              isRequired={isResortRequired}
              withFlightsVisible={withFlightsVisible}
              arrivalGatewayResorts={arrivalGatewayResorts}
            />
          </div>
        </div>
      )}

      <div
        className={`row center-xs middle-xs qq-element-wrap qq-styled-calendar ${
          monthsToDisplay > 0 ? '' : 'hidden-xs-min-up'
        }`}
      >
        <div
          className={
            styledCustomClass
              ? 'styled__calendar xs-7 sm-12'
              : 'styled__calendar xs-12'
          }
        >
          <Calendar
            defaultText={defaultText}
            iconCaret={iconCaret}
            iconCalendar={iconCalendar}
            saveInputDate={saveInputDate}
            theme={calendarTheme}
            checkinDateName={checkinDateName}
            checkoutDateName={checkoutDateName}
            customSingleInputCheckDateLabel={customSingleInputCheckDateLabel}
            inputCheckDateVerticalDivider={inputCheckDateVerticalDivider}
            minDate={minDate}
            maxDate={maxDate}
            minimumNights={minimumNights}
            monthsToDisplay={monthsToDisplay}
            vacationData={vacationData}
            saveVacationData={saveVacationData}
            alignment={calendarAlign}
            onInputClick={onInputClick}
            portalClass={portalClass}
            resetInput={resetQQ}
            onApplyDates={onApplyDates}
            onClearDates={onClearDates}
            onCloseCalendar={onCloseCalendar}
            styledCustomClass={styledCustomClass}
            resortOptions={resortOptions}
            withFlightsVisible={withFlightsVisible}
            isRequired={isDatesRequired}
            setAirportArrivingTo={setAirportArrivingTo}
            arrivalGatewayResorts={arrivalGatewayResorts}
            customDateFormat={customDateFormat}
            renderDayContents={renderDayContents}
            blockedDays={blockedDays}
            availableDaysMap={availableDaysMap}
            hideOnCheckout={hideOnCheckout}
            stickyDayHeader={stickyDayHeader}
            defoCheckinDate={defoCheckinDate}
            defoCheckoutDate={defoCheckoutDate}
          />
        </div>
        {adultsSorter && (
          <>
            <div
              className={
                styledCustomClass
                  ? 'rooms__sorter rooms__adult non-sticky-desktop xs-7 sm-12'
                  : 'rooms__sorter rooms__adult non-sticky-desktop xs-12'
              }
            >
              <Dropdown
                readOnly
                activeCategory={adultNumber}
                name="rooms-adult"
                options={numberOfAdults}
                dropdownHandler={() => {}}
                className="sorter"
              />
            </div>
            <div
              className={
                styledCustomClass
                  ? 'rooms__sorter rooms__adult sticky-mobile xs-7 sm-12'
                  : 'rooms__sorter rooms__adult sticky-mobile xs-12'
              }
            >
              <i className="ic ic-review-avatar" />
              <Dropdown
                readOnly
                activeCategory={adultNumber}
                name="rooms-adult"
                options={numberOfAdultsShorten}
                dropdownHandler={() => {}}
                className="sorter"
              />
            </div>
          </>
        )}
        <div className="styled__prices xs-12">
          <WithPricesElement
            totalPrice={roomPrice}
            numberOfNights={numberOfNights}
            isOnlyTotal={isOnlyTotal}
          />
        </div>
        {styledCustomClass && (
          <div className="xs-4 qq-styled-mobile-button">
            <MainButton
              copy={mainButton}
              bottomElement={bottomElement}
              vacationData={vacationData}
              buttonAction={buttonAction}
              targetBlank={targetBlank}
              customCallback={customCallback}
              resortOptions={resortOptions}
              withFlightsVisible={withFlightsVisible}
              isResortRequired={isResortRequired}
              isDatesRequired={isDatesRequired}
            />
          </div>
        )}
      </div>

      <div className="divider horizontal" />

      {withFlights && (
        <div className="row center-xs middle-xs with-flights-vertical qq-element-wrap">
          <div className="xs-12">
            <WithFlightsElement
              withFlightsId={withFlightsId}
              value={withFlightsVisible}
              onClick={toggleWithFlights}
              labelCopy="Include Roundtrip Flights"
            />
          </div>
        </div>
      )}
      {withFlights && withFlightsVisible && (
        <div className="row center-xs middle-xs with-flights-vertical qq-element-wrap">
          <div className="xs-12">
            <div className="row">
              <FlightsElement
                searchCriteria={searchCriteria}
                handleSearchCriteriaChange={handleSearchCriteriaChange}
                selectGateway={selectGateway}
                resetInput={resetQQ}
                flightsElementClassName="xs-12 flights-departing"
                searchCriteriaClassName="xs-12 qq-resort-select"
                airportArrivingToClassName="xs-12 airport-arriving qq-resort-select"
                handleAirportArrivingTo={handleAirportArrivingTo}
                airportArrivingTo={airportArrivingTo}
                setAirportArrivingTo={setAirportArrivingTo}
                vacationData={vacationData}
                arrivalGatewayResorts={arrivalGatewayResorts}
                isRequired
                isOcjAdditionalLabel={isOcjAdditionalLabel}
              />
            </div>
          </div>
        </div>
      )}
      {mainButton && (
        <div className="row center-xs middle-xs qq-element-wrap qq-styled-main-button">
          <div className="xs-12">
            <MainButton
              copy={mainButton}
              bottomElement={bottomElement}
              vacationData={vacationData}
              buttonAction={buttonAction}
              targetBlank={targetBlank}
              customCallback={customCallback}
              resortOptions={resortOptions}
              withFlightsVisible={withFlightsVisible}
              isResortRequired={isResortRequired}
              isDatesRequired={isDatesRequired}
            />
          </div>
        </div>
      )}
      {viewAllOffersBtn && <ViewAllOffersBtn />}
    </div>
  );
};

const CustomQQ = props => {
  const { theme } = props;

  const QQOptions = new Map([
    ['pricesQQ', PricesQQ],
    ['standalone-button', StandaloneButtonQQ],
    ['default', RoomsQQ],
  ]);

  const QQtype = QQOptions.get(theme) || QQOptions.get('default');

  return <QQtype {...props} />;
};

export default CustomQQ;
