import React from 'react';
import PropTypes from 'prop-types';

const ABTastyWrapper = props => {
  const { fallback = null, name, children, className } = props;

  return (
    <>
      <div id={`${name}-variant`} className={`${className} hide-test`}>
        {children}
      </div>
      <div id={`${name}-default`} className={`${className} show-test`}>
        {fallback}
      </div>
    </>
  );
};

ABTastyWrapper.propTypes = {
  name: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  fallback: PropTypes.node,
  className: PropTypes.string,
};

ABTastyWrapper.defaultProps = {
  name: '',
  children: null,
  fallback: null,
  className: '',
};

export default ABTastyWrapper;
