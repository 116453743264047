import getCookie from './get-cookie';

// isException determines if the elements will be hidden or included only in the provided regions
const hideByRegion = (regions, elements, isException = false, count = 0) => {
  const currRegion = getCookie('USER_REGION');

  let countryCode;

  let counter = count ? count : 0;

  counter++;

  if (counter >= 20) {
    return;
  }

  if (currRegion) {
    countryCode = currRegion.slice(0, 2);
    const regionIsSelected = regions.some(region => region === countryCode);

    if (isException ? !regionIsSelected : regionIsSelected) {
      elements.forEach(element => {
        element.classList.add('none');
      });
    }
  } else {
    // When user loads website on a new session, userRegion variable is undefined.
    setTimeout(() => {
      hideByRegion(regions, elements, isException, counter);
    }, 300);
  }
};

export default hideByRegion;
