import React, { useState, useEffect } from 'react';
import getCookie from '../../../modules/includes/get-cookie';

const UkVisitor = () => {
  const region = getCookie('USER_REGION');
  const ukVisitorPopup = getCookie('UK_VISITOR');

  const [popup, setPopup] = useState(false);

  if (region === 'GB' && !ukVisitorPopup) {
    setPopup(true);

    const currentDate = new Date();

    currentDate.setDate(currentDate.getDate() + 30);

    const expirationDate = currentDate.toLocaleString('en-us', {
      weekday: 'short',
      day: 'numeric',
      month: 'short',
      year: 'numeric',
    });

    document.cookie = `UK_VISITOR=true; expires=${expirationDate} 23:59:59 GMT; path=/;`;
  }

  const closeModal = () => {
    setPopup(false);
  };

  const escFunction = event => {
    if (event.keyCode === 27) {
      closeModal();
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', escFunction, false);

    return () => {
      document.removeEventListener('keydown', escFunction, false);
    };
  }, []);

  if (!popup) {
    return false;
  }

  return (
    <div id="modal-bg" className={'cover-modal'} onClick={closeModal}>
      <div className="uk-visitor-popup" onClick={e => e.stopPropagation()}>
        <i
          onClick={closeModal}
          className="ic ic-close"
          role="presentation"
          aria-label="icon"
          tabIndex="0"
        />
        <div className="modal__content">
          <h2>BROWSING FROM THE UK?</h2>
          <strong>
            For the best experience, head to the dedicated UK website.
          </strong>
          <p>
            Browse and book Sandals holidays in GBP, book flight inclusive
            packages from UK airports, and receive post-booking support from the
            UK team by heading to Sandals.co.uk.
          </p>
          <a href="https://www.sandals.co.uk" target="_blank">
            VISIT SANDALS.CO.UK
          </a>
        </div>
      </div>
    </div>
  );
};

export default UkVisitor;
