import React from 'react';

import SpecificDatesHorizontalRegular from './specificDates/index';
import SpecificDatesHorizontalSpecial from './specificDates/special';

const SpecificDatesHorizontal = props => {
  const { horizontalSpecial } = props;

  return (
    <>
      {!horizontalSpecial && <SpecificDatesHorizontalRegular {...props} />}
      {horizontalSpecial && <SpecificDatesHorizontalSpecial {...props} />}
    </>
  );
};

export default SpecificDatesHorizontal;
