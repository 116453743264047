/**
 * Dropdown
 */
import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import useWindowDimensions from '~/js/modules/hooks/useWindowDimensions';
import OutsideAlerter from '~/js/components/global/outside-alerter/OutsideAlerter';

const Custom = ({ data }) => {
  const {
    showDefault,
    defaultOption,
    name,
    label,
    options,
    activeCategory,
    dropdownHandler,
    showAllOptions,
    disableOutsideAlerter,
    readOnly,
  } = data;
  const [open, setOpen] = useState(false);

  const toggleDropdown = () => {
    if (!readOnly) {
      setOpen(!open);
    }
  };

  const handleDropdown = value => {
    dropdownHandler && dropdownHandler(value, name);
    toggleDropdown();
  };

  return (
    <Fragment>
      <label
        className={`dropdown__label ${label ? '' : 'none'}`}
        htmlFor={name}
      >
        {label ? label : `Choose one of the following ${name}`}
      </label>
      <div
        tabIndex={0}
        role="button"
        onKeyPress={() => toggleDropdown()}
        onClick={() => toggleDropdown()}
        className={`dropdown__header${open ? ' dropdown__header--open' : ''}${
          showDefault && activeCategory ? ' dropdown__selected' : ''
        }`}
      >
        {showDefault && !activeCategory
          ? defaultOption.label
          : options.length > 0 &&
            options.find(item => item.value === activeCategory)?.label}
        <i
          className={`ic ic-bottom-arrow${
            open ? ' ic-bottom-arrow--open' : ''
          }`}
        />
      </div>
      {open && (
        <OutsideAlerter
          handler={disableOutsideAlerter ? () => {} : toggleDropdown}
        >
          <ul id={name} className="dropdown__select">
            {options.map((item, index) => {
              const defaultActive =
                item.value === activeCategory && index === 0;

              return (
                <li
                  key={`option-${index}`}
                  className={`select__option${
                    showDefault && defaultActive && !showAllOptions
                      ? ' hidden-xs-min-up'
                      : ''
                  }${
                    item.value === activeCategory
                      ? ' select__option--active'
                      : ''
                  }`}
                >
                  <button
                    className="select__button"
                    type="button"
                    onClick={() => handleDropdown(item.value)}
                  >
                    {item.label}
                  </button>
                </li>
              );
            })}
          </ul>
        </OutsideAlerter>
      )}
    </Fragment>
  );
};

const Native = ({ data }) => {
  const {
    showDefault,
    defaultOption,
    name,
    label,
    options,
    dropdownHandler,
    activeCategory,
    readOnly,
    photosBool,
  } = data;

  const handleChange = e => {
    dropdownHandler(e.target.value, e.target.name);

    if (photosBool) {
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }
  };

  return (
    <Fragment>
      <label
        className={`dropdown__label ${label ? '' : 'none'}`}
        htmlFor={name}
      >
        {label ? label : `Choose one of the following ${name}`}
      </label>

      <select
        id={name}
        className="dropdown__select"
        name={name}
        onChange={e => handleChange(e)}
        value={activeCategory}
        disabled={readOnly}
      >
        {!activeCategory && showDefault && (
          <option className="dropdown__option" value={defaultOption.value}>
            {defaultOption.label}
          </option>
        )}

        {options.map((item, index) => {
          return (
            <option
              key={`option-${index}`}
              className="dropdown__option"
              value={item.value}
            >
              {item.label}
            </option>
          );
        })}
      </select>
    </Fragment>
  );
};

const Dropdown = props => {
  const {
    showDefault,
    defaultOption,
    dropdownHandler,
    label,
    name,
    options,
    className,
    activeCategory,
    showAllOptions,
    disableOutsideAlerter,
    readOnly,
    photosBool,
  } = props;
  const { width } = useWindowDimensions();
  const isMobile = width < 992;
  const DropdownType = isMobile ? Native : Custom;

  return (
    <div className={`${className ? `${className}__dropdown` : ''} dropdown`}>
      <DropdownType
        data={{
          showDefault,
          defaultOption,
          label,
          name,
          options,
          dropdownHandler,
          activeCategory,
          showAllOptions,
          disableOutsideAlerter,
          readOnly,
          photosBool,
        }}
      />
    </div>
  );
};

Dropdown.propTypes = {
  showDefault: PropTypes.bool,
  defaultOption: PropTypes.shape({
    value: PropTypes.string,
    name: PropTypes.string,
    label: PropTypes.string,
  }),
  name: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  dropdownHandler: PropTypes.func.isRequired,
  className: PropTypes.string,
  label: PropTypes.string,
  photosBool: PropTypes.bool,
};

Dropdown.defaultProps = {
  defaultOption: {
    value: 'default',
    label: 'Select Category',
    name: 'default',
  },
  className: 'default',
  readOnly: false,
};

export default Dropdown;
