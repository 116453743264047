import m from 'moment';
import { obeFormat } from '~/js/components/resorts/rooms/utils/dateUtils';

const isConsecutiveDate = (date, date2, future) => {
  return future
    ? m(date2).subtract(1, 'd').format(obeFormat) === m(date).format(obeFormat)
    : m(date2).add(1, 'd').format(obeFormat) === m(date).format(obeFormat);
};

const checkValidRange = (startDate, endDate, allDatesMap) => {
  if (!startDate && !endDate) {
    return true;
  }

  const parsedStartDate = m(startDate);
  const parsedEndDate = m(endDate);

  if (!parsedStartDate.isValid() || !parsedEndDate.isValid()) {
    return false;
  }

  const formattedStartDate = parsedStartDate.format(obeFormat);
  const endDateWithBuffer = parsedEndDate.add(1, 'd').format(obeFormat);

  const startDateEntry = allDatesMap.get(formattedStartDate);

  if (!startDateEntry) {
    return false;
  }

  const { date: originalDate, nextDates: validRange } = startDateEntry;

  let currentDate = formattedStartDate;

  while (currentDate !== endDateWithBuffer) {
    if (originalDate !== currentDate && !validRange?.includes(currentDate)) {
      return false;
    }
    currentDate = m(currentDate).add(1, 'd').format(obeFormat);
  }

  return true;
};

const classifyReservationDates = apiResponse => {
  const availability = apiResponse?.data?.availability?.[0]?.availability ?? [];

  // Map availability entries to dates with initial structure
  const dates = {
    allDates: {},
    allAvailableDates: [],
    allUnavailableDates: [],
    allCheckoutDates: [],
    allDatesMap: new Map(),
  };

  dates.allDates = availability.map(entry => ({
    date: entry['reservation-date'],
    availableRooms: entry['available-rooms'],
    className: '',
    nextDates: [],
    isCheckoutOnly: false,
    isUnavailable: false,
  }));

  // Loop through each date to classify
  dates.allDates.forEach((date, index) => {
    let nextIndex = index + 1;

    // Find nextDates (future dates with available rooms)
    if (date.availableRooms > 0) {
      while (nextIndex < dates.allDates.length) {
        if (!isConsecutiveDate(date, dates.allDates[nextIndex], true)) {
          break;
        }

        if (dates.allDates[nextIndex].availableRooms <= 0) {
          if (date.nextDates.length >= 2) {
            date.nextDates.push(dates.allDates[nextIndex].date);
          }
          break;
        }
        date.nextDates.push(dates.allDates[nextIndex].date);
        nextIndex++;
      }
    }

    if (date.nextDates.length < 3) {
      date.nextDates = [];
    }

    // Set className based on conditions
    if (date.nextDates.length > 3 && date.availableRooms > 0) {
      date.className = 'available-date';
      dates.allAvailableDates.push(date);
    } else if (date.nextDates.length === 3) {
      date.className = 'available-date';
      dates.allAvailableDates.push(date);

      date.nextDates.forEach((checkoutDate, checkoutIndex) => {
        dates.allDates[index + (checkoutIndex + 1)].className = 'checkout-date';
        dates.allCheckoutDates.push(
          dates.allDates[index + (checkoutIndex + 1)]
        );
        dates.allAvailableDates.push(
          dates.allDates[index + (checkoutIndex + 1)]
        );

        if (checkoutIndex + 1 === date.nextDates.length) {
          dates.allDates[index + (checkoutIndex + 1)].isCheckoutOnly = true;
        }
      });
    } else if (date.className === '') {
      date.className = 'unavailable-date';
      dates.allUnavailableDates.push(date);
      date.isUnavailable = true;
    }

    dates.allDatesMap.set(date.date, date);
  });

  return dates;
};

export { classifyReservationDates, isConsecutiveDate, checkValidRange };
