import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import MicroModal from 'micromodal';
import ModalContent from '~/js/components/global/modal/ModalContent';
import Portal from '~/js/components/global/portal/Portal';
function Modal(props) {
  const { id, className, content, modalHandler } = props;
  const closeTrigger = useRef();
  const isURL = str => {
    const pattern = new RegExp(
      '^(https?:\\/\\/)?' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$',
      'i'
    ); // fragment locator

    return !!pattern.test(str);
  };

  const isYoutube = isURL(content) && content.includes('youtube');

  function closeModalHandler(modalId) {
    MicroModal.close(modalId);
  }

  function mediaHandler() {
    if (isYoutube) {
      // Stop current video
      const mediaPlayer = document.getElementById('modal__iframe');
      const iframeSrc = mediaPlayer.src;

      mediaPlayer.src = iframeSrc;
    }

    modalHandler && modalHandler();
  }

  useEffect(() => {
    MicroModal.show(id, {
      disableScroll: true,
      disableFocus: true,
      onClose: () => mediaHandler(),
    });
  });

  return (
    <Portal id="js-micromodal">
      <ModalContent
        id={id}
        className={className}
        isURL={isURL}
        content={content}
        closeTrigger={closeTrigger}
        closeModalHandler={closeModalHandler}
      />
    </Portal>
  );
}

Modal.propTypes = {
  id: PropTypes.string.isRequired,
  content: PropTypes.any,
  modalHandler: PropTypes.func,
};

Modal.defaultProps = {
  className: 'default',
};

export default Modal;
