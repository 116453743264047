import React, { useEffect, useState } from 'react';
import Modal from '~/js/components/global/modal/Modal';

export const GcvModal = props => {
  const {
    modalHandler,
    originalAddress,
    suggestedAddress,
    handleSelectedAddress,
    addressInfo,
  } = props;

  const [selectedId, setSelectedId] = useState(1);
  const [selectedAddress, setSelectedAddress] = useState('');

  function handleClickOption(e) {
    const { currentTarget } = e;
    const id = parseInt(currentTarget.getAttribute('data-optionid'), 10);
    const address = currentTarget.getAttribute('data-address');

    setSelectedId(id);
    setSelectedAddress(address.split('<br>')[0]);
  }

  function isSelected(id) {
    return id === selectedId;
  }

  function handleContinue() {
    handleSelectedAddress(selectedAddress.split('<br>')[0]);
    modalHandler();
  }

  useEffect(() => {
    setSelectedAddress(suggestedAddress.split('<br>')[0]);
  }, [suggestedAddress]);

  useEffect(() => {
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, []);

  const modalContent = (
    <>
      <div className="row start-xs center-sm middle-xs">
        <div className="xs-12 sm-7">
          <p className="gcv__modal-title">
            There are suggestions for the entered address. Please choose the
            best option:
          </p>
        </div>
      </div>
      <div className="row middle-xs">
        <div className="xs-12 sm-6">
          <div
            className={`gcv__selectable-element${
              isSelected(0) ? ' gcv__selectable-element__selected' : ''
            }`}
            data-optionid="0"
            data-address={originalAddress}
            onClick={handleClickOption}
          >
            <div className="san-inputgroup gcv__input-container">
              <input
                type="radio"
                name="gcv_selectable-address"
                id="gcv_selectable-address__original"
                className="gcv_selectable-address__original"
                onChange={() => {}}
                checked={isSelected(0)}
                value={originalAddress}
              />
            </div>
            <div className="gcv__label-container">
              <span>Original Address</span>
              <p
                dangerouslySetInnerHTML={{
                  __html: originalAddress,
                }}
              />
            </div>
          </div>
        </div>
        <div className="xs-12 sm-6">
          <div
            className={`gcv__selectable-element${
              isSelected(1) ? ' gcv__selectable-element__selected' : ''
            }`}
            data-optionid="1"
            data-address={addressInfo.addressline1}
            onClick={handleClickOption}
          >
            <div className="san-inputgroup gcv__input-container">
              <input
                type="radio"
                name="gcv_selectable-address"
                id="gcv_selectable-address__suggested"
                className="gcv_selectable-address__suggested"
                onChange={() => {}}
                checked={isSelected(1)}
                value={addressInfo.addressline1}
              />
            </div>
            <div className="gcv__label-container">
              <span>Suggested Address</span>
              <p
                dangerouslySetInnerHTML={{
                  __html: suggestedAddress,
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="row center-xs middle-xs">
        <button className="gcv__continue" onClick={handleContinue}>
          CONTINUE
        </button>
      </div>
    </>
  );

  return (
    <Modal
      id="js-gcv__address-modal"
      className="gcv__modal"
      modalHandler={modalHandler}
      content={modalContent}
    />
  );
};

export default GcvModal;
