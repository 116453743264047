import React, { useState } from 'react';
import { TextInput } from '~/js/components/global/form/InputWrapper';
import GCValidations from '~/js/components/global/gcv/index';

const { validateEmail } = GCValidations;
const internals = {};

internals.labels = {
  email: 'Email',
  confirmEmail: 'Confirm Email',
};

export const GcvEmail = ({
  values,
  errors,
  inputHandler,
  errorsHandler,
  handleBlur,
  names,
  customLabels,
  placeholder,
  disabled = false,
}) => {
  const [emailTarget, setEmailTarget] = useState('');
  const { labels } = internals;

  const handleGCVError = event => {
    event.preventDefault();
    errorsHandler({
      ...errors.gcv,
      [names.email]: '',
    });
  };

  const gcvValidator = async value => {
    let errorMessage = '';

    if (value) {
      const resp = await validateEmail(value);
      const { code } = resp;

      if (code) {
        errorMessage = (
          <>
            Are you sure this is a valid email address?&nbsp;
            <button type="gcvEmail" onClick={e => handleGCVError(e)}>
              Yes
            </button>
          </>
        );
      }

      inputHandler(`${[names.email]}Invalid`, code);
      errorsHandler({
        ...errors.gcv,
        [names.email]: errorMessage,
      });
    }
  };

  const handleFocusChange = e => {
    setEmailTarget(e.target.value || '');
  };

  const handleBlurChange = e => {
    const { value } = e.target;

    handleBlur(e);

    if (value !== emailTarget && !errors[names.email]) {
      gcvValidator(values[names.email]);
    }
  };

  return (
    <div
      className={`row start-xs san-form-group group-no-mb ${
        errors.gcv[names.email] ? 'is-invalid' : ''
      }`}
    >
      <div className="xs-12">
        <TextInput
          label={
            customLabels && customLabels.email
              ? customLabels.email
              : labels.email
          }
          placeholder={placeholder}
          name={names.email}
          type="text"
          onFocus={handleFocusChange}
          onBlur={handleBlurChange}
          disabled={disabled}
          required
        >
          {errors.gcv[names.email] && !errors.formik[names.email] && (
            <span className="san-feedback">{errors.gcv[names.email]}</span>
          )}
        </TextInput>

        {names.confirmEmail && (
          <TextInput
            autoComplete="off"
            onPaste={e => {
              e.preventDefault();

              return false;
            }}
            onDrop={e => {
              e.preventDefault();

              return false;
            }}
            label={
              customLabels && customLabels.confirmEmail
                ? customLabels.confirmEmail
                : labels.confirmEmail
            }
            name={names.confirmEmail}
            type="text"
            required
            disabled={disabled}
          />
        )}
      </div>
    </div>
  );
};

GcvEmail.defaultProps = {
  placeholder: 'Email address',
};

export default GcvEmail;
