import React, { useState, useEffect } from 'react';
import m from 'moment';

const Countdown = props => {
  const {
    endDate: rawEndDate,
    bigCounter,
    counterVisible,
    hasActiveSale = false,
    counterAlwaysVisible,
    counterTitle,
  } = props;
  const mEndDate = m(rawEndDate, 'MM-DD-YYYY', true);
  const endDateValid = mEndDate.isValid;
  const countdownSlideIndex = hasActiveSale ? 1 : 0;

  const [counter, setCounter] = useState({ dd: 1, hh: 18, mm: 26, ss: 19 });
  const [visibleClass, setVisibleClass] = useState('qq-element-visible');

  const no = n => (n <= 9 ? `0${n}` : n);

  useEffect(() => {
    let ticker = null;

    // todo: validate enddate with momment and if valid then do ticker
    if (endDateValid) {
      ticker = setInterval(() => {
        const now = new Date();
        const yyyy = mEndDate.year();
        const mo = mEndDate.month();
        const d = mEndDate.date();
        // const endDate = new Date(`${rawEndDate} 23:59:59.999`);
        const endDate = new Date(yyyy, mo, d, 23, 59, 59, 999); // firefox is very strict

        // get total seconds between the times
        let delta = Math.abs(endDate - now) / 1000;
        // calculate (and subtract) whole days
        const days = Math.floor(delta / 86400);

        delta -= days * 86400;
        // calculate (and subtract) whole hours
        const hours = Math.floor(delta / 3600) % 24;

        delta -= hours * 3600;
        // calculate (and subtract) whole minutes
        const minutes = Math.floor(delta / 60) % 60;

        delta -= minutes * 60;
        // what's left is seconds
        const seconds = Math.floor(delta % 60);

        // const tick = new Date(endDate - now);

        // const mNow = m(now);
        // const mEndDate = m(endDate);
        const dd = days;
        const hh = hours;
        const mm = minutes;
        const ss = seconds;

        setCounter({
          dd,
          hh,
          mm,
          ss,
        });
      }, 1000);
    } else {
      console.warn(
        '[Warning] Quickquote prop endDate needs to be of format "MM-DD-YYYY" or is an invalid date format'
      );
    }

    if (props.killCounter) {
      clearInterval(ticker);
    }

    if (props.carouselItemId > countdownSlideIndex) {
      setVisibleClass('qq-element-collapsed');
    } else {
      setVisibleClass('qq-element-uncollapsed');
    }

    return () => {
      clearInterval(ticker);
    };
  });

  const { theme = 'light', endDate } = props;

  let counterWidth = 'xs-12 sm-5 md-4 lg-3 xl-4';

  if (theme === 'light' || theme === 'dark') {
    counterWidth = 'xs-8 md-7 lg-8';
  }

  return (
    <div
      className={`row center-xs middle-xs qq-element-wrap qq-element-transition counter theme-${theme} ${
        bigCounter ? 'big-counter' : 'normal-counter'
      } ${visibleClass} ${
        counterVisible || counterAlwaysVisible ? '' : 'hidden-xs-min-up'
      }`}
    >
      {counterTitle && (
        <div className="counter-title">
          <p>{counterTitle}</p>
        </div>
      )}
      <time dateTime={endDate} />
      <div className={`${counterWidth} no-gutters-xs-min-up`}>
        <div className="row middle-xs fortyfive-kek">
          <div className="xs-3 time-element">
            <span className="time-unit">{no(counter.dd)}</span>
            <span className="time-words">DAYS</span>
          </div>
          <div className="xs-3 time-element">
            <span className="time-unit">{no(counter.hh)}</span>
            <span className="time-words">HOURS</span>
          </div>
          <div className="xs-3 time-element">
            <span className="time-unit">{no(counter.mm)}</span>
            <span className="time-words">MIN.</span>
          </div>
          <div className="xs-3 time-element">
            <span className="time-unit">{no(counter.ss)}</span>
            <span className="time-words">SEC.</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Countdown;
