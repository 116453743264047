import getCookie from './get-cookie';

export const regionPhoneNumbers = {
  MX: '001-800-726-3057',
  BR: '0021-8000-726-3257',
  CO: '01-800-518-9434',
  CA: '1-800-545-8283',
  others: {
    countries: [
      'AR',
      'PY',
      'UY',
      'CL',
      'PE',
      'PA',
      'EC',
      'HN',
      'NI',
      'BZ',
      'CR',
      'SV',
      'GT',
      'BO',
      'VE',
      'GY',
      'GF',
      'SR',
    ],
    phone: '305-284-1300 (EXT 2780)',
    href: '30528413002780',
  },
};

export const replacePhone = (originalNums, string, isHref = false) => {
  const region = getCookie('USER_REGION');

  const allRegions = [
    ...Object.keys(regionPhoneNumbers),
    ...regionPhoneNumbers.others.countries,
  ];

  if (allRegions.every(val => val !== region)) {
    return string;
  }

  let phoneStr = string;

  originalNums.forEach(num => {
    phoneStr = phoneStr.replaceAll(
      num,
      regionPhoneNumbers[region] ??
        (isHref
          ? regionPhoneNumbers.others.href
          : regionPhoneNumbers.others.phone)
    );
  });

  return phoneStr;
};

export const replacePhoneElement = (originalNums, elements) => {
  elements.forEach(element => {
    element.innerHTML = replacePhone(originalNums, element.innerHTML);

    if (element.href) {
      element.href = replacePhone(originalNums, element.href, true);
    }
    element.classList.remove('transparent');
  });
};
