export async function validateAddress(
  parStreet1 = '',
  parStreet2 = '',
  parStreet3 = '',
  parCountry = '',
  parState = '',
  parCity = '',
  parSuite = '',
  parZipcode = ''
) {
  const ret = { code: null, content: {} };

  // const dummyData = {"street1":"260-C North El Camino Real","street2":"","street3":"","country":"US","state":"CA","city":"Encinitas","suite":"","zipcode":"92024-2852"}

  try {
    const resp = await fetch('/api/gcv/address/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        street1: parStreet1,
        street2: parStreet2,
        street3: parStreet3,
        country: parCountry,
        state: parState,
        city: parCity,
        suite: parSuite,
        zipcode: parZipcode,
      }),
    });
    const gcvData = await resp.json();

    // const defaultGcvResponse = {
    //   data: {
    //     gcvResponse: {
    //       PROPOSED_ARGUMENTS: {
    //         ADDRESSLINE1: 'DEFAULT_ADDRESSLINE1',
    //         ADDRESSLINE2: 'DEFAULT_ADDRESSLINE2',
    //         ADDRESSLINE3: 'DEFAULT_ADDRESSLINE3',
    //         CITY: 'DEFAULT_CITY',
    //         COUNTRY: 'DEFAULT_COUNTRY',
    //         POSTALCODE: 'DEFAULT_POSTALCODE',
    //         STATE: 'DEFAULT_STATE',
    //       },
    //       RESPONSE_RESULTS: {
    //         SUCCESS: 'UNKNOWN',
    //       },
    //     },
    //   },
    //   status: 'UNKNOWN',
    // };

    const { gcvResponse } = gcvData.data;
    const { data: gcvResponseData, status: gcvResponseStatus } = gcvResponse;
    const { gcvResponse: gcvResponseTwice } = gcvResponseData;
    const { PROPOSED_ARGUMENTS, RESPONSE_RESULTS } = gcvResponseTwice;
    const {
      ADDRESSLINE1: addressline1,
      ADDRESSLINE2: addressline2,
      ADDRESSLINE3: addressline3,
      CITY: city,
      COUNTRY: country,
      POSTALCODE: zipcode,
      STATE: state,
    } = PROPOSED_ARGUMENTS;
    const { SUCCESS, SHOWSUGGESTEDADDR } = RESPONSE_RESULTS;

    if (
      gcvData.status === 'success' &&
      gcvResponseStatus === 'success' &&
      SHOWSUGGESTEDADDR.toLowerCase() === 'true' &&
      SUCCESS.toLowerCase() === 'true'
    ) {
      const address = `${addressline1}<br>${city}, ${state} ${zipcode}, ${country}`;

      ret.code = true;
      ret.content = {
        address,
        addressline1,
        addressline2,
        addressline3,
        city,
        state,
        zipcode,
        country,
      };
    } else {
      ret.code = false;
    }
    // else if (
    //   SHOWSUGGESTEDADDR.toLowerCase() === 'false' &&
    //   SUCCESS.toLowerCase() === 'false' &&
    //   RESPMESG.toLowerCase() !== ''
    // ) {
    //   ret.error = {
    //     errorMessage: RESPMESG,
    //   };
    //   ret.code = false;
    // }
  } catch (error) {
    console.log('error: ', error.message);
    ret.code = false;
  }

  return ret;
}

export async function validatePhone(
  phoneNumber = '',
  countryCode = 'US',
  inputType = 'mobile'
) {
  const ret = { code: null };

  try {
    const resp = await fetch('/api/gcv/phone/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        phoneNumber,
        CountryCode: countryCode,
        inputType,
      }),
    });
    const data = await resp.json();

    const defaultGcvResponse = {
      data: {
        gcvResponse: {
          STATUSNUMBER: 201,
        },
      },
    };

    const { gcvResponse = defaultGcvResponse } = data.data;
    const { data: gcvResponseData } = gcvResponse;
    const { gcvResponse: gcvResponseTwice } = gcvResponseData;
    const { STATUSNUMBER } = gcvResponseTwice;

    if (
      data.status === 'success' &&
      STATUSNUMBER !== 201 &&
      STATUSNUMBER !== 200
    ) {
      ret.code = true;
    } else {
      ret.code = false;
      ret.data = gcvResponseTwice;
    }
  } catch (error) {
    ret.code = false;
  }

  return ret;
}

export async function validateEmail(email = '') {
  const ret = { code: null };

  try {
    const resp = await fetch('/api/gcv/email/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email }),
    });
    const data = await resp.json();

    const defaultGcvResponse = {
      data: {
        gcvResponse: {
          STATUSNUMBER: 201,
        },
      },
    };

    const { gcvResponse = defaultGcvResponse } = data.data;
    const { data: gcvResponseData } = gcvResponse;
    const { gcvResponse: gcvResponseTwice } = gcvResponseData;
    const { STATUSNUMBER } = gcvResponseTwice;

    if (
      data.status === 'success' &&
      STATUSNUMBER !== 201 &&
      STATUSNUMBER !== 200
    ) {
      ret.code = true;
    } else {
      ret.code = false;
    }
  } catch (error) {
    ret.code = false;
  }

  return ret;
}

const GCValidations = {
  validateAddress,
  validatePhone,
  validateEmail,
};

export default GCValidations;
