import React from 'react';

export const FormMessage = ({
  message = '',
  type = 'info',
  showDefaultMessage = true,
}) => {
  const defaultMessage = showDefaultMessage
    ? 'There was a problem with your form submission. Please try again. '
    : '';

  return (
    <>
      {message !== '' && (
        <dialog
          open
          className={`san-dialog ${type}`}
          aria-label="important-dialog"
        >
          <div className="warnblock">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="4.641"
              height="15.974"
              viewBox="0 0 4.641 15.974"
            >
              <path
                id="Path_7283"
                data-name="Path 7283"
                d="M10.283,18.457H6.738L6.19,8.295h4.641Zm-4.05,3.76a1.979,1.979,0,0,1,.58-1.52A2.381,2.381,0,0,1,8.5,20.154a2.3,2.3,0,0,1,1.66.542,2.01,2.01,0,0,1,.564,1.52,1.956,1.956,0,0,1-.585,1.509,2.319,2.319,0,0,1-1.638.542,2.4,2.4,0,0,1-1.665-.542A1.928,1.928,0,0,1,6.233,22.217Z"
                transform="translate(-6.19 -8.295)"
                fill="#fff"
              />
            </svg>
          </div>
          <div
            className="message-wrapper"
            dangerouslySetInnerHTML={{
              __html: defaultMessage + message,
            }}
          />
        </dialog>
      )}
    </>
  );
};

export default FormMessage;
