import React from 'react';
import PropTypes from 'prop-types';

const ModalContent = props => {
  const { id, className, isURL, content, closeTrigger, closeModalHandler } =
    props;
  const isUrl = isURL(content);

  return (
    <>
      <div
        className={`${className}__modal modal micromodal-slide`}
        id={id}
        aria-hidden="true"
      >
        <button
          className="modal__close"
          data-micromodal-close
          ref={closeTrigger}
        />
        <div className="modal__overlay">
          <div
            className={`modal__container ${isUrl ? '' : 'scroll'}`}
            role="dialog"
            aria-modal="true"
            aria-labelledby={`${id}-title`}
          >
            <div className="modal__content" id={`${id}-content`}>
              <div className={`${isUrl ? 'iframe' : 'static'}-scaler`}>
                {isUrl ? (
                  <>
                    <button
                      className="modal__close__button"
                      aria-label="Close modal"
                      onClick={() => closeModalHandler(id)}
                    >
                      <i
                        role="presentation"
                        aria-label="icon"
                        className="ic ic-close-narrow"
                      />
                    </button>
                    <iframe
                      id="modal__iframe"
                      className="modal__iframe"
                      title="Media Player"
                      width="100%"
                      height="100%"
                      frameBorder="0"
                      allowFullScreen
                      src={content}
                    />
                  </>
                ) : (
                  <div className="modal__static">
                    <button
                      className="modal__close__button"
                      aria-label="Close modal"
                      onClick={() => closeModalHandler(id)}
                    >
                      <i
                        role="presentation"
                        aria-label="icon"
                        className="ic ic-close-narrow"
                      />
                    </button>
                    {content}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal__backdrop"
        tabIndex="-1"
        onClick={() => closeModalHandler(id)}
      />
    </>
  );
};

ModalContent.propTypes = {
  id: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
  content: PropTypes.any.isRequired,
  isURL: PropTypes.func.isRequired,
  closeTrigger: PropTypes.object.isRequired,
  closeModalHandler: PropTypes.func.isRequired,
};

ModalContent.defaultProps = {
  isURL: false,
};

export default ModalContent;
