import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import yupValidations from '~/js/components/global/form/utils/formValidations';
import formActions from '~/js/components/global/form/utils/formActions';
import GcvAddress from '~/js/components/global/gcv/GcvAddress';
import GcvEmail from '~/js/components/global/gcv/GcvEmail';
import Submit from '~/js/components/global/form/Submit';
import FormMessage from '~/js/components/global/form/FormMessage';
import { TextInput, Checkbox } from '~/js/components/global/form/InputWrapper';

const { post, errorChecker, sanitizeValues } = formActions;

const internals = {};

internals.setSubscribedCookie = () => {
  document.cookie =
    'ALREADY_SUBSCRIBED=true; Path=/; expires=Fri, 31 Dec 9999 23:59:59 GMT;';
};

const GcvForm = props => {
  const [thankYou, setThankYou] = useState(false);
  const [gcvErrors, setGcvErrors] = useState({});
  const [message, setMessage] = useState('');

  const optInCta = document.querySelector('.opt-in__wrapper');

  const submitForm = async values => {
    const formFields = sanitizeValues({
      ...values,
    });

    const { code, message: submissionMessage } = await post(
      '/api/brochure/subscription/submit/',
      formFields
    );

    if (code === 1) {
      setThankYou(true);
      internals.setSubscribedCookie();
      optInCta.classList.add('none');
    } else {
      setThankYou(false);
      setMessage(submissionMessage);
    }
  };

  return (
    <>
      {!thankYou && (
        <>
          {props.isModal && (
            <>
              <h3 className="subscription-title">
                SIGN UP TO GET THE LATEST NEWS & DEALS
              </h3>
              <p className="subscription-description">
                Be the first to know about exclusive offers, new and exciting
                updates, destination tips, delivered straight to your inbox
              </p>
            </>
          )}
          <Formik
            initialValues={{
              firstName: '',
              email: '',
              country: '',
              subscribe: false,
              createdFrom: 'SAN_LATEST_DEAL',
            }}
            validationSchema={Yup.object({
              firstName: yupValidations.string.required,
              email: yupValidations.email,
              country: yupValidations.string.required,
              subscribe: yupValidations.boolean.required,
            })}
            validateOnMount
            onSubmit={submitForm}
          >
            {({
              /* touched, */
              values,
              errors,
              isSubmitting,
              setFieldValue,
              setFieldTouched,
              handleBlur,
            }) => (
              <Form className="subscription-form">
                <div className="row">
                  <div className="xs-12">
                    <div className="row center-xs middle-xs">
                      <div className={props.formClassnames}>
                        <FormMessage message={message} type="error" />
                        <TextInput
                          required
                          label="Name"
                          name="firstName"
                          type="text"
                          placeholder="Name"
                        />
                        <GcvEmail
                          values={values}
                          inputHandler={setFieldValue}
                          handleBlur={handleBlur}
                          errorsHandler={setGcvErrors}
                          errors={{
                            formik: errors,
                            gcv: gcvErrors,
                          }}
                          names={{
                            email: 'email',
                          }}
                        />
                        <GcvAddress
                          values={values}
                          inputHandler={setFieldValue}
                          handleBlur={handleBlur}
                          names={{
                            country: 'country',
                          }}
                        />
                      </div>
                      <div className={props.checkboxClassnames}>
                        <div className="row start-xs san-form-group">
                          <div className="xs-12">
                            <div className="san-inputgroup">
                              <Checkbox
                                name="subscribe"
                                label={
                                  <span>
                                    I want to receive promotional emails about
                                    Sandals Resorts from Unique Travel Corp.,
                                    the Worldwide Representative of Sandals
                                    Resorts, c/o P.O. Box SS-19020, Nassau,
                                    Bahamas. You can contact us at&nbsp;
                                    <a href="mailto:info@sandals.com">
                                      info@sandals.com
                                    </a>
                                    . You may unsubscribe at any time.
                                  </span>
                                }
                              />
                            </div>
                          </div>
                        </div>
                        <Submit
                          label={props.ctaLabel}
                          className={props.ctaClassnames}
                          readyForSubmit={errorChecker(errors, gcvErrors)}
                          errors={{ ...errors, ...gcvErrors }}
                          isSubmitting={isSubmitting}
                          setFieldTouched={setFieldTouched}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </>
      )}
      {thankYou && (
        <>
          <h3 className="subscription-title">THANK YOU!</h3>
          <p className="subscription-description">
            You will receive a confirmation email shortly. Please make sure you
            click on the “VERIFY” button to guarantee delivery.
          </p>
          {props._closeModal && (
            <button className="san-submit" onClick={props._closeModal}>
              CLOSE
            </button>
          )}
        </>
      )}
    </>
  );
};

export default GcvForm;
