const levelsInfo = [
  // {
  //   id: 0,
  //   name: 'Love Nest Luxury Suites',
  //   icon: 'lovenest-level',
  //   description:
  //     'Defined by exclusive amenities and unique designs, these luxurious suites and villas create the ultimate escape for lovebirds. These well–appointed suites feature lavish bathrooms and amenities such as plush Terry robes, hand–embroidered Egyptian cotton duvets, HD smart TVs, and the attentive service of a personal butler.',
  //   url: '/accommodations/love-nest/',
  // },
  {
    id: 0,
    name: 'Butler Elite',
    icon: 'butler-level',
    description:
      'These accommodations offer all that you would expect in a well–staffed grand home with every amenity imaginable. Over–the–top luxurious baths, an en–suite bar stocked with top–shelf liquors and wines, and the most indulgent bedding that inspires your best night’s sleep ever. Everything feels better, tastes better, and creates the most of memories when luxury is priority one.',
    url: '/all-inclusive/butler/',
  },
  {
    id: 1,
    name: 'Club Sandals',
    icon: 'club-level',
    description:
      'A place with extraordinary privileges, unique experiences, and exceptional service for concierge guests of Sandals Resorts. Extravagance is blended with remarkable amenities that completely redefine genuine hospitality and allow guests to enjoy one–on–one service and complete relaxation in the comfort of a stylish lounge.',
    url: '/all-inclusive/sandals-club/',
  },
  {
    id: 2,
    name: 'Luxury Level',
    icon: 'luxury-level',
    description:
      'Personified – that’s what you can expect when you indulge in a Sandals Luxury All Inclusive vacation. Even our basic rooms offer luxurious furnishings, highest quality linens and fine in–room amenities. At Sandals you’re always immersed in utmost privilege and distinction in your own private sanctuary.',
    url: '',
  },
];

const numberOfAdults = [
  {
    id: '2-adults',
    value: 0,
    label: '2 Adults',
  },
];

const numberOfAdultsShorten = [
  {
    id: '2-adults',
    value: 0,
    label: '2',
  },
];

const cardStyleOptions = [
  {
    id: 0,
    name: 'row',
  },
  {
    id: 1,
    name: 'column',
  },
];

const sorterOptions = [
  {
    id: 'sort-by-lowest',
    value: 0,
    label: 'Price: Low to High',
  },
  {
    id: 'sort-by-highest',
    value: 1,
    label: 'Price: High to Low',
  },
];

const inputOptions = [
  {
    name: 'Room Levels',
    type: 'levels',
    input: 'radio',
  },
  {
    name: 'Room Views',
    type: 'views',
    input: 'checkbox',
  },
  {
    name: 'Room Types',
    type: 'roomTypes',
    input: 'checkbox',
  },
  {
    name: 'Room Features',
    type: 'features',
    input: 'checkbox',
  },
  {
    name: 'Room Specials',
    type: 'promotions',
    input: 'checkbox',
  },
];

const createLevelFilter = arr => {
  const allValues = arr.map(el => el.roomType);
  const repeatedValues = [].concat.apply([], allValues);
  const uniqueValues = repeatedValues.filter((v, i, a) => a.indexOf(v) === i);

  const roomLevels = ['ALL', ...uniqueValues].map((el, index) => {
    let name;

    let value;

    switch (el) {
      case 'ALL':
        name = 'View All';
        value = 'ALL';
        break;
      // case 'LOVENEST':
      //   name = 'Love Nest Suite';
      //   value = 'LOVENEST';
      //   break;
      case 'BUTLER':
        name = 'Butler Elite';
        value = 'BUTLER';
        break;
      case 'CLUB':
        name = 'Club Sandals';
        value = 'CLUB';
        break;
      case 'LUXURY':
        name = 'Sandals Luxury';
        value = 'LUXURY';
        break;
      default:
        name = el.name;
    }

    return {
      id: index,
      value: value,
      name: name,
      checked: value === 'ALL' ? true : false,
      disabled: null,
      type: 'levels',
    };
  });

  return roomLevels;
};

const createViewsFilter = arr => {
  const allValues = arr.map(el => el.views.map(view => view.name));
  const repeatedValues = [].concat.apply([], allValues);
  const uniqueValues = repeatedValues.filter((v, i, a) => a.indexOf(v) === i);

  const options = uniqueValues.map((el, index) => {
    return {
      id: index,
      value: el,
      name: el,
      checked: false,
      disabled: false,
      type: 'views',
    };
  });

  return options;
};

const createRoomTypeFilter = arr => {
  const allValues = arr.map(el =>
    el.roomTypes.map(roomType => roomType.roomAmenityName)
  );
  const repeatedValues = [].concat.apply([], allValues);
  const uniqueValues = repeatedValues.filter((v, i, a) => a.indexOf(v) === i);

  const options = uniqueValues.map((el, index) => {
    return {
      id: index,
      value: el,
      name: el,
      checked: false,
      disabled: false,
      type: 'roomTypes',
    };
  });

  return options;
};

const createFeaturesFilter = arr => {
  const allValues = arr.map(el =>
    el.features.map(feature => feature.roomAmenityName)
  );
  const repeatedValues = [].concat.apply([], allValues);
  const uniqueValues = repeatedValues.filter((v, i, a) => a.indexOf(v) === i);

  const options = uniqueValues.map((el, index) => {
    return {
      id: index,
      value: el,
      name: el,
      checked: false,
      disabled: false,
      type: 'features',
    };
  });

  return options;
};

const createSpecialsFilter = arr => {
  const allValues = arr.map(el =>
    el.promotions.map(promotion => promotion.promotionTitle)
  );
  const repeatedValues = [].concat.apply([], allValues);
  const uniqueValues = repeatedValues.filter((v, i, a) => a.indexOf(v) === i);

  return uniqueValues.map((el, index) => {
    return {
      id: index,
      value: el,
      name: el,
      checked: false,
      disabled: false,
      type: 'promotions',
    };
  });
};

const filterOptions = rooms => {
  return {
    levels: createLevelFilter(rooms),
    views: createViewsFilter(rooms),
    features: createFeaturesFilter(rooms),
    promotions: createSpecialsFilter(rooms),
    roomTypes: createRoomTypeFilter(rooms),
  };
};

const applyLevels = (rooms = [], level = {}) => {
  const levelHandler = roomType => {
    let arr;

    if (roomType === 'ALL') {
      arr = rooms;
      // } else if (roomType === 'LOVENEST') {
      //   arr = rooms.filter(room => {
      //     return room.attributes.find(
      //       attribute => attribute.attributeName === 'Love Nest'
      //     );
      //   });
      // }
    } else {
      arr = rooms.filter(room => {
        return room.roomType === roomType;
      });
    }

    return arr;
  };

  return levelHandler(level.value);
};

const applyFilters = (
  roomsClone = [],
  rooms = [],
  filter = {},
  usedFilters = []
) => {
  let results;

  const filterHandler = category => {
    let arr;

    if (filter.type === 'promotions') {
      arr = category.find(el => el.promotionTitle === filter.value);
    } else if (filter.type === 'features' || filter.type === 'roomTypes') {
      arr = category.find(el => el.roomAmenityName === filter.value);
    } else {
      arr = category.find(el => el.name === filter.value);
    }

    return arr;
  };

  if (filter && !filter.checked) {
    results = rooms.filter(room => {
      let arr;

      if (filter.type === 'views') {
        arr = filterHandler(room.views);
      } else if (filter.type === 'features') {
        arr = filterHandler(room.features);
      } else if (filter.type === 'roomTypes') {
        arr = filterHandler(room.roomTypes);
      } else {
        arr = filterHandler(room.promotions);
      }

      return arr;
    });
  } else {
    if (usedFilters.length > 0) {
      results = roomsClone.filter(room =>
        usedFilters.every(usedEl => {
          let arr;

          if (usedEl.type === 'levels') {
            if (usedEl.value === 'ALL') {
              arr = room.roomType !== 'undefined';
              // } else if (usedEl.value === 'LOVENEST') {
              //   arr = room.attributes.find(
              //     attribute => attribute.attributeName === 'Love Nest'
              //   );
              // }
            } else {
              arr = room.roomType === usedEl.value;
            }
          }

          if (usedEl.type === 'views') {
            arr = room.views.find(view => view.name === usedEl.value);
          }
          if (usedEl.type === 'features') {
            arr = room.features.find(
              feature => feature.roomAmenityName === usedEl.value
            );
          }
          if (usedEl.type === 'roomTypes') {
            arr = room.roomTypes.find(
              roomType => roomType.roomAmenityName === usedEl.value
            );
          }
          if (usedEl.type === 'promotions') {
            arr = room.promotions.find(
              promotion => promotion.promotionTitle === usedEl.value
            );
          }

          return arr;
        })
      );
    } else {
      results = roomsClone;
    }
  }

  return results;
};

export {
  applyLevels,
  applyFilters,
  filterOptions,
  sorterOptions,
  cardStyleOptions,
  inputOptions,
  levelsInfo,
  numberOfAdults,
  numberOfAdultsShorten,
};
