import * as Yup from 'yup';
import formActions from '~/js/components/global/form/utils/formActions';
const {
  minChar,
  exactChar,
  validateZip,
  validateMob,
  validateFileType,
  errorMsg,
  validateUSPhone,
} = formActions;

const phoneYup = (areaCodeName, isRequired = true) => {
  let defaultPhoneYup = Yup.string();

  if (isRequired) {
    defaultPhoneYup = defaultPhoneYup.required(errorMsg('required'));
  }

  defaultPhoneYup = defaultPhoneYup.test(
    'validateMob',
    errorMsg('validateMob'),
    phoneVal => (phoneVal ? validateMob(phoneVal) : true)
  );

  return Yup.string().when(areaCodeName, areaCode => {
    if (areaCode) {
      if (areaCode === 'US-1') {
        return defaultPhoneYup
          .test('validateExactChar', errorMsg('validateMob'), phoneVal =>
            phoneVal ? exactChar(phoneVal, 10) : true
          )
          .test('validateUSPhone', errorMsg('validateMob'), phoneVal =>
            phoneVal ? validateUSPhone(phoneVal) : true
          )
          .test('validateMob', errorMsg('validateMob'), phoneVal => phoneVal); // if the user sets a area code, the phone number becomes required by default
      }

      return defaultPhoneYup
        .test('validateMinChar', errorMsg('minChar'), phoneVal =>
          minChar(phoneVal)
        )
        .test('validateMob', errorMsg('validateMob'), phoneVal => phoneVal); // if the user sets a area code, the phone number becomes required by default
    }

    return defaultPhoneYup.test(
      'validateAreaCode',
      errorMsg('validateAreaCode'),
      () => (isRequired ? false : true)
    );
  });
};

const confirmEmailYup = emailName => {
  return Yup.string().when(emailName, email => {
    if (email) {
      return Yup.string().test(
        'compareEmails',
        errorMsg('compareEmails'),
        confirmEmail => email === confirmEmail
      );
    }

    return Yup.string().required(errorMsg('required'));
  });
};

const zipCodeYup = countryName => {
  return Yup.string().when(countryName, country => {
    if (country) {
      return Yup.string()
        .required(errorMsg('required'))
        .test('validateZip', errorMsg('validateZip'), zipCode =>
          validateZip(country, zipCode)
        );
    }

    return Yup.string().required(errorMsg('required'));
  });
};

const fileUploadYup = (fileTypes, maxSize) => {
  return Yup.mixed()
    .required(errorMsg('required'))
    .test('validateFileType', errorMsg('validateFileType'), value => {
      if (value) {
        return validateFileType(value, fileTypes);
      }

      return () => false;
    })
    .test('maxFileSize', errorMsg('maxFileSize'), value => {
      if (value) {
        return value?.size <= maxSize;
      }

      return () => false;
    });
};

const multipleFilesYup = (fileTypes, maxSize) => {
  return Yup.mixed()
    .required(errorMsg('required'))
    .test('requireArray', errorMsg('required'), value => {
      return value.length > 0;
    })
    .test('validateFileType', errorMsg('validateFileType'), value => {
      if (value) {
        return value.every(file => validateFileType(file, fileTypes));
      }

      return () => false;
    })
    .test(
      'maxFileSize',
      errorMsg('maxFileSize', null, null, Math.ceil(maxSize / 1000000)),
      value => {
        if (value) {
          return value.every(file => file?.size <= maxSize);
        }

        return () => false;
      }
    );
};

const yupValidations = {
  string: {
    required: Yup.string().required(errorMsg('required')),
  },
  boolean: {
    required: Yup.bool().oneOf([true], errorMsg('required')),
  },
  recaptcha: Yup.string().nullable().required(errorMsg('required')),
  email: Yup.string()
    .email('Please enter a valid email address.')
    .required(errorMsg('required')),
  confirmEmail: confirmEmailYup,
  zipCode: zipCodeYup,
  phoneNumber: phoneYup,
  file: fileUploadYup,
  multipleFiles: multipleFilesYup,
};

export default yupValidations;
