import React from 'react';
// import ResolveImg from '~/js/components/global/image/ResolveImg';

const FlexibleDatesHorizontal = () => {
  return (
    <div className="row flexible-dates-wrapper">
      <div className="xs-12">
        <div className="row end-lg">
          <div className="xs-12 sm-8 flexible-dates-wraps qq-flexible-dates-left">
            <div className="row center-xs start-sm center-lg middle-xs">
              <div className="xs-8 sm-5 lg-6 qq-big-text">Flexible dates?</div>
              <div className="xs-8 sm-6 lg-5 qq-aside-big-text">
                Find the best Available Price For The Room You Want At The
                Sandals Of Your Choice.
              </div>
            </div>
          </div>
          <div className="xs-12 sm-4 flexible-dates-wraps flexible-dates-wraps-right">
            <div className="row center-xs end-sm middle-xs flexible-dates-wraps-button">
              <a
                href="/flexible-dates"
                className="xs-12 lg-10 qq-flex-dates-blk"
              >
                View Best Prices
              </a>
              <small className="sm-12 lg-10 qq-best-price-guarantee">
                best price guarantee
              </small>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FlexibleDatesHorizontal;
