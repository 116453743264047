const smoothScroll = () => {
  const main = document.querySelector('html, body');
  const isScrollModal = document.querySelector(
    '.is-open .scroll, .custom-modal--active'
  );
  const scrollParam = window.location.search.includes('scrollTo');
  const goToActiveTab = window.location.search.includes('goToActiveTab');
  const targetAnchors = Array.from(
    document.getElementsByClassName('js-scroll')
  );
  const stickyNav = document.querySelector('.sticky-nav');

  let offsetTop = 0;

  let actualTarget = null;

  function checkOffsetTop(target) {
    const isDesktop = window.innerWidth >= '768';

    let top = isDesktop ? target.offsetTop - 47 : target.offsetTop - 93;

    if (stickyNav !== null) {
      const stickyNavHeight = stickyNav.offsetHeight;
      const checkDesktop = window.innerWidth >= '1200';

      if (isDesktop) {
        top = target.offsetTop - stickyNavHeight - 77;
      } else if (checkDesktop) {
        top = target.offsetTop - stickyNavHeight - 87;
      } else {
        top = target.offsetTop - stickyNavHeight - 50;
      }
    }

    return top;
  }

  function checkOffsetTopScrollAnimation(target, scrollCheck) {
    if (!target || actualTarget !== target) {
      return;
    }

    if (checkOffsetTop(target) !== offsetTop) {
      offsetTop = checkOffsetTop(target);
      main.scrollTo({
        top: offsetTop,
        left: 0,
        behavior: 'smooth',
      });
    } else {
      setTimeout(() => {
        window.removeEventListener('scroll', scrollCheck);
      }, 1200);
    }
  }

  // Animation
  function smoothScrollAnimation(target) {
    offsetTop = checkOffsetTop(target);

    if (isScrollModal !== null) {
      isScrollModal.scrollTo({
        top: offsetTop,
        behavior: 'smooth',
      });
    } else {
      actualTarget = target;

      const scrollCheck = () => {
        checkOffsetTopScrollAnimation(target, scrollCheck);
      };

      window.addEventListener('scroll', scrollCheck);

      main.scrollTo({
        top: offsetTop,
        behavior: 'smooth',
      });
    }
  }

  // URL Param
  if (scrollParam) {
    const urlScrollParam = window.location.search.split('scrollTo=')[1];
    const targetHasId = !!document.querySelector(`#${urlScrollParam}`);

    const urlScrollTarget = document.querySelector(
      `${targetHasId ? '#' : '.'}${urlScrollParam}`
    );

    !!urlScrollParam && smoothScrollAnimation(urlScrollTarget);
  }

  if (goToActiveTab) {
    const idGoTo = window.location.search.substring(1).split('=')[1];
    const idGoToTarget = document.querySelector(`#${idGoTo}`);

    if (idGoTo) {
      idGoToTarget.click();
      smoothScrollAnimation(idGoToTarget);
    }
  }

  if (targetAnchors) {
    targetAnchors.forEach(anchor => {
      // Click
      anchor.addEventListener('click', e => {
        const selectedAnchor = e.target;

        let scrollTarget;

        if (
          e.target.tagName === 'BUTTON' ||
          e.target.tagName === 'A' ||
          e.target.tagName === 'I'
        ) {
          e.preventDefault();

          if (e.target.tagName === 'I') {
            scrollTarget = document.querySelector(
              `.${e.target.parentElement.dataset.scrollTo}`
            );
          } else {
            scrollTarget = document.querySelector(
              `.${selectedAnchor.dataset.scrollTo}`
            );
          }
          if (stickyNav !== null && stickyNav.classList.contains('open')) {
            stickyNav.classList.toggle('open');
          }
          smoothScrollAnimation(scrollTarget);
        }
      });

      // Tab
      anchor.addEventListener('keydown', e => {
        const keyCode = e.originalEvent;
        const selectedAnchor = e.target;

        if (keyCode === 13) {
          const scrollTarget = document.querySelector(
            `.${selectedAnchor.dataset.scrollTo}`
          );

          smoothScrollAnimation(scrollTarget);
          window.setTimeout(() => {
            scrollTarget.setAttribute('tabindex', '-1').focus();
          }, 1000);
        }
      });
    });
  }
};

export default smoothScroll;
