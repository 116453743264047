import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Modal from '~/js/components/global/modal/Modal';

const WithPricesElement = props => {
  const { numberOfNights, totalPrice } = props;
  const [modal, setModal] = useState(false);

  const toolTipContent = (
    <div className="row">
      <div className="xs-12">
        <div className="price__info">
          <span>pricing details</span>
          <ul>
            <li>All Prices are shown in US dollars.</li>
            <li>Room priced for 2 adults</li>
            <li>{`Room cancellation available before 12/12/${new Date().getFullYear()}`}</li>
            <li>Room hold available for $98</li>
          </ul>
        </div>
      </div>
    </div>
  );

  const modalHandler = () => {
    setModal(!modal);
  };

  return (
    <>
      {modal && (
        <Modal
          id="price-information-modal"
          className="price-disclaimer"
          modalHandler={modalHandler}
          content={toolTipContent}
        />
      )}
      <div className="row with-prices-element sticky-mobile">
        <div className="xs-12">
          <div className="row">
            <div className="xs-12 no-pg">
              <div className="prices__title">
                <span className="breakdown__total breakdown__total--only">
                  {'Total: '}
                  <span>
                    {`$${Number(totalPrice).toLocaleString('en-US', {
                      minimumFractionDigits: 2,
                    })}`}
                    <i
                      role="presentation"
                      aria-label="icon"
                      className="ic-info"
                      onClick={modalHandler}
                    />
                  </span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row with-prices-element non-sticky-desktop">
        <div className="xs-12">
          <div className="row">
            <div className="xs-7 no-pg">
              <div className="prices__title">
                <h4>{`Total room price (${numberOfNights} Nights):`}</h4>
              </div>
            </div>

            <div className="xs-5 no-pg">
              <div className="prices__breakdown">
                <span className="breakdown__total">{`$${Number(
                  totalPrice
                ).toLocaleString('en-US', {
                  minimumFractionDigits: 2,
                })}`}</span>
                <span className="breakdown__pppn">
                  {`$${Math.round(
                    totalPrice !== 0 && numberOfNights !== 0
                      ? totalPrice / numberOfNights / 2
                      : 0
                  )} PP/PN`}
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="xs-12">
          <div className="prices__disclaimer">
            <ul>
              <li>
                <span>Hold room from only $98.</span>
              </li>
              <li>
                <span>All possible discounts are automatically applied.</span>
              </li>
              <li>
                <span>All Prices in US dollars.</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

WithPricesElement.propTypes = {
  numberOfNights: PropTypes.number,
  totalPrice: PropTypes.number,
};

WithPricesElement.defaultProps = {
  numberOfNights: 0,
  totalPrice: 0,
};

export default WithPricesElement;
