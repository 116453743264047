import React, { useState, useRef, useEffect } from 'react';
import flightsData from './flightsData.json';
import m from 'moment';

const { gateways: rawGateways } = flightsData;

function escapeRegExp(str) {
  return str.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&');
}

const FlightsElement = props => {
  const {
    searchCriteria = '',
    handleSearchCriteriaChange,
    airportArrivingTo = '',
    handleAirportArrivingTo,
    selectGateway,
    flightsElementClassName = 'xs-12',
    searchCriteriaClassName = 'xs-12',
    airportArrivingToClassName = 'xs-12',
    vacationData = {},
    arrivalGatewayResorts = [],
    isRequired = false,
    isOcjAdditionalLabel = true,
    searchCriteriaAdditionalLabel = false,
  } = props;

  const [gateways, setGateways] = useState(rawGateways);
  const [optionsVisible, setOptionsVisible] = useState(false);
  const [departureCity, setDepartureCity] = useState('');
  const [isFlightFromMiami, setIsFlightFromMiami] = useState(false);

  useEffect(() => {
    if (departureCity === '') {
      setIsFlightFromMiami(false);
    }
  }, [departureCity]);

  const toggleOptionsVisible = () => {
    setOptionsVisible(!optionsVisible);
  };

  const flightsElementRef = useRef(null);

  const closeOptions = () => {
    setOptionsVisible(false);
  };

  const handleClick = e => {
    if (
      flightsElementRef.current &&
      !flightsElementRef.current.contains(e.target)
    ) {
      closeOptions();
    }
  };

  const clickGateway = e => {
    const value = e.currentTarget.getAttribute('data-value');
    const label = e.currentTarget.getAttribute('data-label');

    setDepartureCity(label);
    selectGateway({ value, label });
    setIsFlightFromMiami(value === 'MIA');
    closeOptions();

    if (value !== '') {
      const selectedElement = e.currentTarget;
      const closestSelect = selectedElement.closest('.san-form-group');

      if (closestSelect.classList.contains('is-invalid')) {
        closestSelect.classList.toggle('is-invalid');
      }
    }
  };

  const handleDepartureCityChange = e => {
    const { currentTarget } = e;
    const { value } = currentTarget;
    const found = rawGateways.filter(g => {
      const label = `${g.city.toLocaleLowerCase()} ${g.stateName.toLocaleLowerCase()} ${g.state.toLocaleLowerCase()} ${g.country.toLocaleLowerCase()} ${g.gateway.toLocaleLowerCase()}`;

      return label.search(escapeRegExp(value.toLowerCase())) >= 0;
    });

    setDepartureCity(value);
    setGateways(found);
  };

  const registerMouseDown = () => {
    document.addEventListener('mousedown', handleClick);
  };

  const unRegisterMouseDown = () => {
    document.removeEventListener('mousedown', handleClick);
  };

  const getValidDates = () => {
    const checkIn = m(vacationData.checkInDate, 'MM-DD-YYYY').day();
    const checkOut = m(vacationData.checkOutDate, 'MM-DD-YYYY').day();

    const isValidDates =
      (checkIn === 3 || checkIn === 6) && (checkOut === 3 || checkOut === 6);

    return isValidDates;
  };

  useEffect(() => {
    registerMouseDown();

    return () => {
      unRegisterMouseDown();
    };
  }, []);

  return (
    <>
      <div
        className={`${flightsElementClassName} ${
          isRequired ? 'san-form-group' : ''
        }`}
        ref={flightsElementRef}
      >
        <label htmlFor="departure-city">Flights Departing From</label>
        <input
          type="text"
          name="departure-city"
          id="departure-city"
          className={`qq-input departure-city ${isRequired ? 'san-input' : ''}`}
          value={departureCity}
          placeholder="Enter Airport or City"
          autoComplete="off"
          onChange={handleDepartureCityChange}
          onClick={toggleOptionsVisible}
        />
        <i
          role="presentation"
          aria-label="icon"
          className="sandals-icons ic-marker"
        />
        {isRequired && (
          <div className="xs-12 no-pg">
            <div className="has-error">
              <span className="san-feedback">This field is required.</span>
            </div>
          </div>
        )}
        {optionsVisible && (
          <div className="row center-xs middle-xs departure-city-wrap">
            <div className="xs-12">
              <ul className="departure-city-list">
                {gateways.map((gateway, i) => {
                  const first = `${gateway.city}, ${gateway.stateName}(${gateway.state}),`;
                  const second = `${gateway.country} (${gateway.gateway})`;
                  const dataLabel = `${first} ${second}`;
                  const dataValue = `${gateway.gateway}`;

                  return (
                    <li
                      key={i}
                      data-label={dataLabel}
                      data-value={dataValue}
                      onClick={clickGateway}
                    >
                      {dataLabel}
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        )}
      </div>
      {arrivalGatewayResorts.indexOf(vacationData.rstCode) !== -1 &&
        getValidDates() &&
        isFlightFromMiami && (
          <div className={airportArrivingToClassName}>
            <label htmlFor="arriving-to-airport">Airport Arriving To</label>
            <div className="qq-input-wrapper">
              <div className="select-wrapper custom-select">
                <select
                  name="arriving-to-airport"
                  id="arriving-to-airport"
                  className="qq-input arriving-to-airport"
                  value={airportArrivingTo}
                  onChange={handleAirportArrivingTo}
                >
                  <option value="mbj">MONTEGO BAY (MBJ)</option>
                  <option value="ocj">
                    {`OCHO RIOS (OCJ)${
                      !isOcjAdditionalLabel ? ' 15 min from resort' : ''
                    }`}
                  </option>
                </select>
              </div>
            </div>
            {airportArrivingTo === 'ocj' && isOcjAdditionalLabel && (
              <small className="small-arriving-to-airport">
                Located just 15 minutes from your resort
              </small>
            )}
          </div>
        )}
      <div className={searchCriteriaClassName}>
        <label htmlFor="search-criteria">Search Flights By</label>
        <div className="qq-input-wrapper">
          <div className="select-wrapper custom-select">
            <select
              name="search-criteria"
              id="search-criteria"
              className="qq-input search-criteria"
              value={searchCriteria}
              onChange={handleSearchCriteriaChange}
            >
              <option value="economy">Economy/Coach</option>
              <option value="business">First Class/Business</option>
            </select>
          </div>
        </div>
        {searchCriteriaAdditionalLabel && (
          <small className="small-search-criteria">
            Flights will be displayed in the “Flights” section.
          </small>
        )}
      </div>
    </>
  );
};

export default FlightsElement;
