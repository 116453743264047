import { replacePhoneElement } from './includes/replace-phone';
import getCookie from './includes/get-cookie';
import hideByRegion from './includes/hide-by-region';

const footer = () => {
  const footerElem = document.getElementById('js-footer');
  const websitesToggle = document.getElementById('websites-toggle');
  const websitesList = document.getElementById('websites-list');
  const offerSection = footerElem.querySelector('.footer-offer');
  const sweepsPromo = footerElem.querySelector('.sweeps-promo');

  hideByRegion(['US'], [offerSection, sweepsPromo], true);

  const { env = '' } = window.sandals_app;

  const footerNum = footerElem.querySelector(
    '.footer-services.contact-numbers > a'
  );

  replacePhoneElement(
    ['1-888-SANDALS', '888-726-3257', '18887263257'],
    [footerNum]
  );

  function toggleWebsitesList() {
    if (websitesToggle) {
      websitesToggle.classList.toggle('active');
      websitesList.classList.toggle('visible');
    }
  }

  if (websitesToggle) {
    websitesToggle.addEventListener('click', () => {
      toggleWebsitesList();
    });
  }

  if (footerElem) {
    footerElem.addEventListener('keydown', e => {
      const listIsVisible = websitesList.classList.contains('visible');
      const toggleHasFocus = websitesToggle === document.activeElement;
      const listHasFocus = websitesList.contains(document.activeElement);

      if (
        (e.keyCode === 27 &&
          listIsVisible &&
          (listHasFocus || toggleHasFocus)) ||
        (listIsVisible && !toggleHasFocus && !listHasFocus)
      ) {
        toggleWebsitesList();
      }
    });
  }

  const supportToggle = document.getElementById('support-toggle');
  const closeSupportToggle = document.getElementById('close-support-modal');

  function toggleSupportModal() {
    const supportModal = document.getElementById('support-modal');

    supportToggle.classList.toggle('active');
    supportModal.classList.toggle('visible');
  }

  supportToggle.addEventListener('click', () => {
    toggleSupportModal();
  });

  closeSupportToggle.addEventListener('click', () => {
    toggleSupportModal();
  });

  const aboutLinks = document.querySelectorAll(
    '.footer-other-links .other-links p a'
  );

  const isFromCalifornia =
    getCookie('isFromCalifornia') !== null &&
    getCookie('isFromCalifornia') === 'true';

  for (const [index, linkItem] of aboutLinks.entries()) {
    const linkItemHref = linkItem.href;

    if (index === aboutLinks.length - 1 && !isFromCalifornia) {
      linkItem.classList.add('hide-item');
      linkItem.previousElementSibling.classList.add('hide-item');
    }

    if (index === aboutLinks.length - 1 && isFromCalifornia) {
      const link = linkItem;

      if (env !== 'development' && env !== 'DEVELOPMENT') {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const showCookiePreferences = urlParams.get('showCookiePreferences');

        if (showCookiePreferences) {
          link.click();
        }
      }
    }
  }
};

setTimeout(() => {
  const hasSubscription = getCookie('ALREADY_SUBSCRIBED') !== null;
  const userLabel = document.getElementById('accounts-user');
  const isLoggedIn = !userLabel?.classList.contains('hide-item') ?? false;

  const optInCta = document.querySelector('.opt-in__wrapper');

  if (!hasSubscription && !isLoggedIn && optInCta) {
    optInCta.classList.remove('none');
  }
}, 2000);

const toggleActiveClassName = 'toggle__button--active';

const toggleContainerAll = document.querySelectorAll(
  '.js-toggle__container-footer'
);

let lastClassName = null;

const openLinkDropdown = (mainContainer, activeClassName, useCase) => {
  if (useCase === 'listener') {
    const actualButton = mainContainer.querySelector('.js-toggle__button');
    const actualContent = mainContainer.querySelector('.js-toggle__content');
    const currentId = actualButton.getAttribute('data-id');

    actualButton.classList.toggle(activeClassName);

    // If there is a previously expanded section, collapse it
    if (lastClassName != null && lastClassName !== currentId) {
      const lastButton = document.querySelector(`[data-id="${lastClassName}"]`); // Get the last button using data-id
      const lastContent = lastButton
        .closest('.js-toggle__container-footer')
        .querySelector('.js-toggle__content');

      lastButton.classList.remove(activeClassName);
      lastContent.style.maxHeight = 0;
    }

    if (actualButton.classList.contains(activeClassName)) {
      actualContent.style.maxHeight = `${actualContent.scrollHeight + 30}px`;
      lastClassName = currentId;
    } else {
      actualContent.style.maxHeight = 0;
      lastClassName = null;
    }
  } else {
    for (let i = 0; i < mainContainer.length; i++) {
      const actualButton = mainContainer[i].querySelector('.js-toggle__button');
      const actualContent = mainContainer[i].querySelector(
        '.js-toggle__content'
      );

      if (useCase === 'media') {
        if (window.innerWidth >= 768) {
          actualButton.classList.add(toggleActiveClassName);
          actualContent.style.maxHeight = `${
            actualContent.scrollHeight + 30
          }px`;
        } else {
          actualButton.classList.remove(toggleActiveClassName);
          actualContent.style.maxHeight = 0;
        }
      } else if (useCase === 'init') {
        actualButton.classList.add(toggleActiveClassName);
        actualContent.style.maxHeight = `${actualContent.scrollHeight + 30}px`;
      }
    }
  }
};

toggleContainerAll.forEach(toggleContainer => {
  const toggleButton = toggleContainer.querySelector('.js-toggle__button');

  toggleButton.addEventListener('click', () => {
    openLinkDropdown(toggleContainer, toggleActiveClassName, 'listener');
  });
});

const tabletBreakpoint = window.matchMedia('(min-width: 768px)');

tabletBreakpoint.addEventListener('change', () => {
  toggleContainerAll.forEach(() => {
    openLinkDropdown(toggleContainerAll, toggleActiveClassName, 'media');
  });
});

if (window.innerWidth >= 768) {
  toggleContainerAll.forEach(() => {
    openLinkDropdown(toggleContainerAll, toggleActiveClassName, 'init');
  });
}

export default footer;
