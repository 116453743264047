import React from 'react';

const WithFlightsElement = props => {
  const {
    value,
    onClick,
    labelCopy = 'Include Roundtrip Flights',
    id = 'with-flights',
  } = props;

  return (
    <div className="row center-xs top-xs middle-md with-flights-element">
      <div className="xs-12">
        <input
          type="checkbox"
          name={id}
          className="qq-checkbox with-flights"
          id={id}
          onChange={onClick}
          value={value}
          checked={value}
          autoComplete="off"
        />
        <label htmlFor={id}>{labelCopy}</label>
      </div>
    </div>
  );
};

export default WithFlightsElement;
