import React, { useState } from 'react';
import { TextInput, Select } from '~/js/components/global/form/InputWrapper';
import { Country, State, City } from 'country-state-city';
import OutsideAlerter from '~/js/components/global/outside-alerter/OutsideAlerter';
import formActions from '~/js/components/global/form/utils/formActions';
import GcvModal from '~/js/components/global/gcv/GcvModal';
import GCValidations from '~/js/components/global/gcv/index';

const { validateAddress } = GCValidations;
const { sortCountries } = formActions;
const internals = {};

internals.countries = countryCodes => {
  const allCountries = sortCountries(Country.getAllCountries());

  if (countryCodes.length > 0) {
    return allCountries.filter(country =>
      countryCodes.includes(country.isoCode)
    );
  }

  return allCountries;
};

internals.labels = {
  address: 'Address',
  suite: 'Apt / Suite',
  country: 'Country',
  state: 'State / Province',
  city: 'City',
  zipCode: 'Zip / Postal Code',
};

export const GcvAddress = ({
  inputHandler,
  values,
  handleBlur,
  names,
  customLabels,
  specialCountries = [],
  customClassNames,
  disabled = false,
}) => {
  const { countries, labels } = internals;
  const [autocomplete, setAutocomplete] = useState(false);
  const [suggestionModal, setSuggestionModal] = useState(false);
  const [suggestedAddress, setSuggestedAddress] = useState('');
  const [addressInfo, setAddressInfo] = useState({
    country: '',
    state: '',
    zipcode: '',
    city: '',
  });

  const [addressTarget, setAddressTarget] = useState({
    address: '',
    country: '',
    state: '',
    city: '',
    zipCode: '',
  });

  const originalAddress = `${values[names.address]}<br>${values[names.city]}, ${
    values[names.state]
  } ${values[names.zipCode]}, ${values[names.country]}`;

  function handleSelectedAddress(selectedAddress) {
    let countryCode;

    let stateCode;

    if (addressInfo.country.length > 2) {
      const countryIso = countries(specialCountries).find(
        country => country.name === addressInfo.country
      )?.isoCode;

      countryCode = countryIso;
    } else {
      countryCode = addressInfo.country;
    }

    if (addressInfo.state.length > 2) {
      const stateIso = State.getStatesOfCountry(countryCode).find(
        state => state.name === addressInfo.state
      )?.isoCode;

      stateCode = stateIso;
    } else {
      stateCode = addressInfo.state;
    }

    inputHandler([names.address], selectedAddress);
    inputHandler([names.country], countryCode);
    inputHandler([names.state], stateCode);
    inputHandler([names.city], addressInfo.city);
    inputHandler([names.zipCode], addressInfo.zipcode);
  }

  const gcvValidator = async () => {
    if (
      values[names.address] &&
      values[names.country] &&
      values[names.state] &&
      values[names.city] &&
      values[names.zipCode]
    ) {
      // all props are filled and not empty or undefined
      const resp = await validateAddress(
        values[names.address],
        '',
        '',
        values[names.country],
        values[names.state],
        values[names.city],
        values[names.suite] || '',
        values[names.zipCode]
      );
      const { code, content } = resp;
      const {
        address = '',
        addressline1 = '',
        addressline2 = '',
        addressline3 = '',
        country = '',
        state = '',
        city = '',
        zipcode = '',
      } = content;

      setAddressInfo({
        addressline1,
        addressline2,
        addressline3,
        country,
        state,
        city,
        zipcode,
      });

      if ((addressline1, country, state, city, zipcode)) {
        setAddressTarget({
          address: addressline1,
          country,
          state,
          city,
          zipCode: zipcode,
        });
      }

      if (code) {
        setSuggestedAddress(address);
        setSuggestionModal(true);
      }
    }
  };

  const modalHandler = () => {
    setSuggestionModal(false);
  };

  const autoCompleteHandler = (event, { value }) => {
    event.preventDefault();
    inputHandler([names.city], value);
    setAutocomplete(false);
  };

  const handleFocusChange = e => {
    const { name, value } = e.target;

    setAddressTarget({
      ...addressTarget,
      [name]: value,
    });
  };

  const handleBlurChange = e => {
    const { value } = e.target;

    handleBlur(e);

    if (value !== addressTarget[e.target.name]) {
      gcvValidator();
    }
  };

  return (
    <>
      <>
        {names.address && (
          <TextInput
            label={
              customLabels && customLabels.address
                ? customLabels.address
                : labels.address
            }
            name={names.address}
            type="text"
            required
            disabled={disabled}
            onFocus={handleFocusChange}
            onBlur={handleBlurChange}
          />
        )}

        {names.suite && (
          <TextInput
            label={
              customLabels && customLabels.suite
                ? customLabels.suite
                : labels.suite
            }
            name={names.suite}
            type="text"
            disabled={disabled}
          />
        )}
      </>

      {names.country && (
        <Select
          label={
            customLabels && customLabels.country
              ? customLabels.country
              : labels.country
          }
          name={names.country}
          required
          disabled={disabled}
          onFocus={handleFocusChange}
          onBlur={handleBlurChange}
        >
          <option value="" hidden>
            Select a country
          </option>

          {countries(specialCountries).map((countryVal, i) => (
            <option key={`${i}-country`} value={countryVal.isoCode}>
              {countryVal.name}
            </option>
          ))}
        </Select>
      )}

      {names.state &&
        (State.getStatesOfCountry(values[names.country]).length > 0 ? (
          <Select
            label={
              customLabels && customLabels.state
                ? customLabels.state
                : labels.state
            }
            name={names.state}
            required
            disabled={disabled}
            onFocus={handleFocusChange}
            onBlur={handleBlurChange}
          >
            <option value="" hidden>
              Select an option...
            </option>

            {State.getStatesOfCountry(values[names.country]).map(
              (stateVal, i) => (
                <option key={`${i}-state`} value={stateVal.isoCode}>
                  {stateVal.name}
                </option>
              )
            )}
          </Select>
        ) : (
          <TextInput
            label={
              customLabels && customLabels.state
                ? customLabels.state
                : labels.state
            }
            name={names.state}
            type="text"
            disabled={disabled || (!values[names.country] && true)}
            required
            onFocus={handleFocusChange}
            onBlur={handleBlurChange}
            placeholder={
              State.getStatesOfCountry(values[names.country]).length > 0 &&
              values[names.country]
                ? ''
                : 'Select a country first'
            }
          />
        ))}
      {names.city && (
        <div className="row">
          {names.city && (
            <div
              className={
                customClassNames && customClassNames.city
                  ? customClassNames.city
                  : !names.zipCode
                  ? 'xs-12'
                  : 'xs-6'
              }
            >
              <OutsideAlerter handler={() => setAutocomplete(false)}>
                <TextInput
                  label={
                    customLabels && customLabels.city
                      ? customLabels.city
                      : labels.city
                  }
                  autoComplete="off"
                  name={names.city}
                  type="text"
                  required
                  disabled={disabled}
                  onFocus={e => {
                    handleFocusChange(e);
                    setAutocomplete(true);
                  }}
                  onBlur={handleBlurChange}
                >
                  <ul
                    className={`san-autocomplete ${
                      autocomplete &&
                      values[names.country] &&
                      values[names.state]
                        ? 'show'
                        : 'hide'
                    }`}
                  >
                    {City.getCitiesOfState(
                      values[names.country],
                      values[names.state]
                    )
                      .filter(item =>
                        item.name
                          .toLocaleLowerCase()
                          .startsWith(values[names.city].toLocaleLowerCase())
                      )
                      .map(
                        (item, i) =>
                          i < 10 && (
                            <li key={`${i}-city`}>
                              <button
                                type="autocomplete"
                                onClick={event =>
                                  autoCompleteHandler(event, {
                                    value: item.name,
                                  })
                                }
                              >
                                {item.name}
                              </button>
                            </li>
                          )
                      )}
                  </ul>
                </TextInput>
              </OutsideAlerter>
            </div>
          )}

          {names.zipCode && (
            <div
              className={
                customClassNames && customClassNames.zipCode
                  ? customClassNames.zipCode
                  : 'xs-6'
              }
            >
              <TextInput
                label={
                  customLabels && customLabels.zipCode
                    ? customLabels.zipCode
                    : labels.zipCode
                }
                name={names.zipCode}
                type="text"
                disabled={disabled}
                required
                onFocus={handleFocusChange}
                onBlur={handleBlurChange}
              />
            </div>
          )}
        </div>
      )}

      {suggestionModal && (
        <GcvModal
          modalHandler={modalHandler}
          originalAddress={originalAddress}
          suggestedAddress={suggestedAddress}
          handleSelectedAddress={handleSelectedAddress}
          addressInfo={addressInfo}
        />
      )}
    </>
  );
};

export default GcvAddress;
