import React, { useState, useEffect } from 'react';
import getCookie from '../../../modules/includes/get-cookie';
import GcvForm from '~/js/components/global/subscription/GcvFormik';

const SubscribeAndSave = props => {
  const [notSubscribed, setNotSubscribed] = useState(false);
  const [popup, setPopup] = useState(false);
  const [closed, setClosed] = useState(false);
  const [clickToggle, setClickToggle] = useState(false);

  const _closeModal = () => {
    document.querySelector('#modal-bg').classList.remove('cover-modal');
    document.querySelector('body').classList.remove('stop-scrolling');
    setClosed(true);
    setClickToggle(false);
  };

  const _escFunction = event => {
    if (event.keyCode === 27) {
      _closeModal();
    }
  };

  const _protectedPages = () => {
    if (notSubscribed) {
      document.querySelector('body').classList.add('stop-scrolling');
    }

    const hasSubscription = getCookie('ALREADY_SUBSCRIBED') !== null;
    const pages = [
      '/specials/',
      '/top-10-reasons/',
      '/resorts/',
      '/disclaimers/',
      '/group-upload/',
      '/corpair-upload/',
      '/publicrelations/',
      '/leadgen/',
    ];

    const optInCta = document.querySelector('.opt-in__cta');

    optInCta.addEventListener('click', () => {
      document.querySelector('#modal-bg').classList.add('cover-modal');
      setClickToggle(true);
      setClosed(false);
    });

    if (props.requestURI !== undefined) {
      const protectedUrls = pages.map(page => props.requestURI.includes(page));
      const isProtected = protectedUrls.some(url => url);

      if (isProtected || hasSubscription) {
        setPopup(false);
        document.querySelector('body').classList.remove('stop-scrolling');
      } else {
        setPopup(true);
      }
    } else if (props.requestURI === undefined) {
      setPopup(false);
      document.querySelector('body').classList.remove('stop-scrolling');
    }
  };

  const countVisitedTimes = () => {
    if (getCookie('VISITED_TIMES') === null) {
      document.cookie =
        'VISITED_TIMES=1; Path=/; expires=Fri, 31 Dec 9999 23:59:59 GMT;';
    } else if (getCookie('ALREADY_SUBSCRIBED') === null) {
      let counter = getCookie('VISITED_TIMES');

      counter++;
      document.cookie = `VISITED_TIMES=${counter}; Path=/; expires=Fri, 31 Dec 9999 23:59:59 GMT;`;
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', _escFunction, false);
    countVisitedTimes();
    const newNotSubscribed =
      getCookie('ALREADY_SUBSCRIBED') === null &&
      getCookie('VISITED_TIMES') === '3';

    setNotSubscribed(newNotSubscribed);

    _protectedPages();

    return () => {
      document.removeEventListener('keydown', _escFunction, false);
    };
  }, []);

  useEffect(() => {
    if (popup && notSubscribed) {
      document.querySelector('body').classList.add('stop-scrolling');
    } else {
      document.querySelector('body').classList.remove('stop-scrolling');
    }
  }, [popup, notSubscribed]);

  useEffect(() => {
    if (clickToggle) {
      document.querySelector('body').classList.add('stop-scrolling');
    } else {
      document.querySelector('body').classList.remove('stop-scrolling');
    }
  }, [clickToggle]);

  const hideModal = !(popup && notSubscribed) && !clickToggle ? 'none' : '';

  return (
    <div
      id="modal-bg"
      className={`${hideModal} ${
        notSubscribed || clickToggle ? 'cover-modal' : ''
      } modal-wrapper`}
      onClick={_closeModal}
    >
      <div
        className={'subscription-popup' + (closed ? ' closed' : '')}
        onClick={e => e.stopPropagation()}
      >
        <i
          onClick={_closeModal}
          onKeyPress={_closeModal}
          className="ic ic-close"
          tabIndex="0"
        />
        <GcvForm
          _closeModal={_closeModal}
          ctaLabel="Sign Up"
          isModal
          ctaClassnames="xs-4 sm-3"
          formClassnames="xs-12 sm-10 lg-9 no-gutters-lg-min-up no-labels"
          checkboxClassnames="xs-12 sm-10 lg-9 no-gutters-lg-min-up"
        />
      </div>
    </div>
  );
};

export default SubscribeAndSave;
